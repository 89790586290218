import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from 'react-router-dom';
import api from '../../../axiosApi/api';
import JSZip from 'jszip';

import AdminProjectInstancesUtilsBar from '../../UtilsBar/ProjectInstancesUtilsBar/AdminProjectInstancesUtilsBar';
import DataChunkAnnotatorsModal from "../../Modals/AnnotatorsModal/DataChunkAnnotatorsModal/DataChunkAnnotatorsModal";
import ProjectAnnotatorsModal from "../../Modals/AnnotatorsModal/ProjectAnnotatorsModal/ProjectAnnotatorsModal";
import DataChunkStatisticsModal from "../../Modals/StatisticsModal/DataChunkStatisticsModal/DataChunkStatisticsModal";
import InstanceStatisticsModal from "../../Modals/StatisticsModal/InstanceStatisticsModal/InstanceStatisticsModal";
import ProjectStatusModal from "../../Modals/ProjectStatusModal/ProjectStatusModal";
import ImportAnnotationsModal from "../../Modals/ImportAnnotationsModal/ImportAnnotationsModal";

import "./AdminProjectInstancesDashboard.css";
import './../Dashboard.css';

import naIcon from "../../../images/naNew.png";
import statsIcon from "../../../images/stats.png";
import RightArrowIcon from "../../../images/right-arrow.png";
import DownArrowIcon from "../../../images/down-arrow.png";
import AnnotatorsIcon from "../../../images/annotators.png";
import DownloadIcon from "../../../images/download-icon.png";
import ErrorIcon from "../../../images/error-icon.png"
import ReplayIcon from "../../../images/replay.png"

function AdminProjectInstancesDashboard() {
  const location = useLocation();
  const currentPath = location.pathname;
  const {
    taskType: taskTypeParam, 
    taskView: taskViewParam, 
    annotationType: annotationTypeParam, 
    projectModality: projectModalityParam, 
    projectLabels: projectLabelsParam, 
    projectStatus: projectStatusParam
  } = location.state || {};
  const { projectId } = useParams();

  const [projectDetails, setProjectDetails] = useState({
    taskType: taskTypeParam,
    taskView: taskViewParam,
    annotationType: annotationTypeParam,
    projectModality: projectModalityParam,
    projectLabels: projectLabelsParam,
    projectStatus: projectStatusParam
  });

  const [dataChunks, setDataChunks] = useState([]);
  const [dataChunksPerAnnotationModule, setDataChunksPerAnnotationModule] = useState([]);
  const [dataChunkPendingPercentages, setDataChunkPendingPercentages] = useState([]);
  const [expandedStates, setExpandedStates] = useState([]);

  const [openModals, setOpenModals] = useState({
    filters: false,
    projectAssignments: false,
    dataChunkAssignments: false,
    dataChunkStatistics: false,
    instanceStatistics: false,
    projectStatus: false,
    importAnnotations: false
  });
  
  const [selectedDataChunk, setSelectedDataChunk] = useState(null);
  const [selectedDataChunkIndex, setSelectedDataChunkIndex] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [selectedFilteringOption, setSelectedFilteringOption] = useState("All instances");
  const [selectedAnnotationModuleIndex, setSelectedAnnotationModuleIndex] = useState(0);

  const [loadingDataChunks, setLoadingDataChunks] = useState(true);
  const [error, setError] = useState(false);


  useEffect(() => {
    if(selectedFilteringOption==="Automatic annotations"){
      fetchDataChunksPerAnnotationModule();
    }
    else if(selectedFilteringOption==="All instances") {
      if(projectId)
        fetchInstancesPerDataChunk();
    }
    return () => {};
  },[selectedFilteringOption])


  useEffect(() => {
    if (dataChunksPerAnnotationModule.length > 0) {
      // Initialize expanded states and pending percentages
      const expandedStatesInit = new Array(dataChunksPerAnnotationModule[selectedAnnotationModuleIndex].length).fill(false);
      const pendingPercentagesInit = new Array(dataChunksPerAnnotationModule[selectedAnnotationModuleIndex]['dataChunks'].length).fill(0);
      
      // Set the initial expanded states
      setExpandedStates(expandedStatesInit);
  
      // Calculate pending annotations percentages for each data chunk
      const updatedPercentages = pendingPercentagesInit.map((_, dataChunkIndex) => {
        return measurePendingAnnotationsPercentage(dataChunkIndex); // Call the function to measure percentage
      });
  
      // Update the state with the new percentages
      setDataChunkPendingPercentages(updatedPercentages);
    }
  }, [selectedAnnotationModuleIndex, dataChunksPerAnnotationModule]);


  const fetchInstancesPerDataChunk = async () => {
    setError(false);
    setLoadingDataChunks(true);

    try {
      const res = await api.get(`/api/projects/${projectId}/data-chunks`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') },
        params: { 'include-instances-metadata': "true" }
      });
      setDataChunks(res.data.dataChunks);
      setExpandedStates(new Array(res.data.dataChunks.length).fill(false));
    } catch (error) {
      setLoadingDataChunks(false);
      setError(true);
      console.error("Error fetching instances:", error);
    } finally {
      setLoadingDataChunks(false);
    }
  };

  const fetchDataChunksPerAnnotationModule = async () => {
    setError(false);
    setLoadingDataChunks(true);

    try {
      const res = await api.get(`/api/projects/${projectId}/data-chunks-per-annotation-module`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') },
        params: { 'include-instances-metadata': "true" }
      });
      setDataChunksPerAnnotationModule(res.data.dataChunks);
      if(res.data.dataChunks.length>0)
        setExpandedStates(new Array(res.data.dataChunks[0].length).fill(false));
    } catch (error) {
      setLoadingDataChunks(false);
      setError(true);
      console.error("Error fetching automatically annotated instances:", error);
    } finally {
      setLoadingDataChunks(false);
    }
  }

  const fetchProjectStatus = async () => {
    try {
      const res = await api.get(`/api/projects/${projectId}`, {
        headers: {"Authorization": 'Bearer ' + localStorage.getItem('jwtToken')}
      });
      setError(false);
      const updatedProjectStatus = res.data.project.projectStatus
      setProjectDetails(prevState => ({
        ...prevState,
        projectStatus: updatedProjectStatus
      }));
    } catch (error) {
      setError(true);
      console.error("Error fetching instances:", error);
    } 
  }


  const handleAnnotationModuleChange = (event) => {
    setSelectedAnnotationModuleIndex(event.target.value);  
  };


  const measurePendingAnnotationsPercentage = (dataChunkIndex) => {
    const totalPendingAnnotations = dataChunksPerAnnotationModule[selectedAnnotationModuleIndex]['dataChunks'][dataChunkIndex]['pendingAnnotations'];
    const numberOfInstances = dataChunksPerAnnotationModule[selectedAnnotationModuleIndex]['dataChunks'][dataChunkIndex]['dataChunkInstances'].length;
    const percentage = ((numberOfInstances - totalPendingAnnotations) / numberOfInstances) * 100
    return percentage
  }


  const checkIfAnnotationMissing = (dataChunkIndex, instanceIndex) => {
    const pendingStatus = dataChunksPerAnnotationModule[selectedAnnotationModuleIndex]['dataChunks'][dataChunkIndex]['dataChunkInstances'][instanceIndex]['pendingAnnotation'];
    if(pendingStatus===1)
      return true
    return false
  }


  const submitAllPendingAnnotations = async(dataChunkIndex) => {
    const annotationModuleId = dataChunksPerAnnotationModule[selectedAnnotationModuleIndex]['_id']
    const dataChunkId = dataChunksPerAnnotationModule[selectedAnnotationModuleIndex]['dataChunks'][dataChunkIndex]['dataChunkId']
  
    try {
      const params = { 'annotation-module-id': annotationModuleId }

      const res = await api.post(`/api/data-chunks/${dataChunkId}/automatic-annotations`, params, {
        headers: { 
          "Authorization": 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json' 
        }
      });

      fetchDataChunksPerAnnotationModule()
    } catch (error) {
      console.error("Error fetching automatically annotated instances:", error);
    } 
  }

  const submitPendingAnnotation = async(dataChunkIndex, instanceIndex) => {
    const annotationModuleId = dataChunksPerAnnotationModule[selectedAnnotationModuleIndex]['_id']
    const dataChunkId = dataChunksPerAnnotationModule[selectedAnnotationModuleIndex]['dataChunks'][dataChunkIndex]['dataChunkId']
    const instanceId = dataChunksPerAnnotationModule[selectedAnnotationModuleIndex]['dataChunks'][dataChunkIndex]['dataChunkInstances'][instanceIndex]['instanceInfo']['_id']
    
    try {
      const params = { 'annotation-module-id': annotationModuleId }

      const res = await api.post(`/api/data-chunks/${dataChunkId}/instances/${instanceId}/automatic-annotations`, params, {
        headers: { 
          "Authorization": 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json' 
        }
      });

      fetchDataChunksPerAnnotationModule()
    } catch (error) {
      console.error("Error fetching automatically annotated instances:", error);
    }
  }

  const toggleExpanded = (index) => {
    setExpandedStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };


  const updateAssignments = (pendingActions) => {
    return new Promise((resolve, reject) => {
      api.put('/api/annotation-modules', pendingActions, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json'
        },
      }).then((res) => {
        resolve({ success: true });
      }).catch((error) => {
        console.log(error);
        reject({ success: false, error: error.message });
      });
    });
  };


  const exportProjectAnnotations = async () => {
    try{
      const res = await api.get(`/api/projects/${projectId}/annotations`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
      })
      
      const jsonString = JSON.stringify(res.data);
      const jsonBlob = new Blob([jsonString], { type: 'application/json' });

      const zip = new JSZip();
      zip.file(`Project_${projectId} annotations.json`, jsonBlob);

      try {
        const zipContent = await zip.generateAsync({ type: 'blob' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipContent);
        link.download = 'annotations.zip';
        link.click();
      } catch (error) {
        console.error("Error generating zip file:", error);
      }

    }catch (error) {
      console.error("Error fetching instance annotations:", error);
    }
  }


  const exportDataChunkAnnotations = async (dataChunkId) => {
    try{
      const res = await api.get(`/api/data-chunks/${dataChunkId}/annotations`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
      })
      
      const jsonString = JSON.stringify(res.data.annotations);
      const jsonBlob = new Blob([jsonString], { type: 'application/json' });

      const zip = new JSZip();
      zip.file(`DataChunk_${selectedDataChunkIndex} annotations.json`, jsonBlob);

      try {
        const zipContent = await zip.generateAsync({ type: 'blob' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipContent);
        link.download = 'annotations.zip';
        link.click();
      } catch (error) {
        console.error("Error generating zip file:", error);
      }

    }catch (error) {
      console.error("Error fetching instance annotations:", error);
    }
  }


  const exportInstanceAnnotations = async (dataChunkId, instanceId) => {
    try{
      const res = await api.get(`/api/data-chunks/${dataChunkId}/instances/${instanceId}/annotations`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
      })
      
      const jsonString = JSON.stringify(res.data);
      const jsonBlob = new Blob([jsonString], { type: 'application/json' });

      const zip = new JSZip();
      zip.file(`DataChunk_${selectedDataChunkIndex} Instance_${selectedInstance['name']} annotations.json`, jsonBlob);

      try {
        const zipContent = await zip.generateAsync({ type: 'blob' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipContent);
        link.download = 'annotations.zip';
        link.click();
      } catch (error) {
        console.error("Error generating zip file:", error);
      }

    }catch (error) {
      console.error("Error fetching instance annotations:", error);
    }
  }


  const importAnnotations = async (jsonData, taskType) => {
    try {
      const params = {
        'json-data': jsonData,
        'task-type': taskType
      };

      const res = await api.post(`/api/projects/${projectId}/external-annotations`, params, {
        headers: { 
          "Authorization": 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json' 
        }
      });

      return { 
        "status": res.status,
        "annotationsStatus": res.data.annotationsStatus
      };
      
    } catch (error) {
      console.log("Error occurred:", error);

      if (error.response) {
        return {
          "status": error.response.status,
          "annotationsStatus": error.response.data.annotationsStatus
        };
      } else if (error.request) {
        return {
          "status": 500,
          "message": "No response received from the server"
        };
      } else {
        return {
          "status": 500,
          "message": "Error setting up the request"
        };
      }
    }
  };



  return (
    <> 
      {
        (openModals.projectAssignments) && (
          <ProjectAnnotatorsModal 
            selectedProjectId = {projectId}
            dataChunks = {dataChunks.map(item => item.id)}
            updateAssignments={updateAssignments}
            onClose={() => setOpenModals(prevState => ({
              ...prevState,
              projectAssignments: false
            }))}    
          />
        )
      }
      {
        (openModals.dataChunkAssignments) && (
          <DataChunkAnnotatorsModal 
            selectedProjectId = {projectId}
            selectedDataChunkId = {selectedDataChunk.id}
            updateAssignments={updateAssignments}
            onClose={() => setOpenModals(prevState => ({
              ...prevState,
              dataChunkAssignments: false
            }))} 
          />
        )
      }
      {
        (openModals.dataChunkStatistics) && (
          <DataChunkStatisticsModal 
            taskType = {projectDetails.taskType}
            dataChunkId = {selectedDataChunk.id}
            dataChunkNumber = {selectedDataChunkIndex}
            dataChunkInstances = {selectedDataChunk.instances}
            taskView = {projectDetails.taskView}
            annotationType = {projectDetails.annotationType}
            projectModality = {projectDetails.projectModality}
            projectLabels = {projectDetails.projectLabels}
            onClose={() => setOpenModals(prevState => ({
              ...prevState,
              dataChunkStatistics: false
            }))} 
          />
        )
      }
      {
        (openModals.instanceStatistics) && (
          <InstanceStatisticsModal
            taskType = {projectDetails.taskType} 
            dataChunkId = {selectedDataChunk.id}
            instanceId = {selectedInstance.id}
            instanceName = {selectedInstance.name}
            instanceRelativePath = {selectedInstance.relativePath}
            taskView = {projectDetails.taskView}
            annotationType = {projectDetails.annotationType}
            projectModality = {projectDetails.projectModality}
            projectLabels = {projectDetails.projectLabels}
            onClose={() => setOpenModals(prevState => ({
              ...prevState,
              instanceStatistics: false
            }))} 
          />
        )
      }
      {
        (openModals.projectStatus) && (
          <ProjectStatusModal 
            projectId = {projectId}
            projectStatus = {projectDetails.projectStatus}
            fetchProjectStatus = {() => fetchProjectStatus()}
            onClose={() => setOpenModals(prevState => ({
              ...prevState,
              projectStatus: false
            }))} 
          />
        )
      }
      {
        (openModals.importAnnotations) && (
          <ImportAnnotationsModal 
            projectTaskType = {taskTypeParam}
            importAnnotations = {(jsonData, taskType) => importAnnotations(jsonData, taskType)}
            onClose={() => setOpenModals(prevState => ({
              ...prevState,
              importAnnotations: false
            }))} 
          />
        )
      }
      
      {/* Main Dashboard Content */}
      <section className="dashboard project-instances-dashboard">
        <h2>Project / <b>Instances per Data Chunk</b></h2>
        <AdminProjectInstancesUtilsBar 
          selectedFilteringOption={selectedFilteringOption}
          setSelectedFilteringOption={setSelectedFilteringOption}
          filteredData={dataChunks}
          setFilteredData={setDataChunks}
          projectStatus = {projectDetails.projectStatus}
          setOpenFiltersModal={() => setOpenModals(prevState => ({
            ...prevState,
            filters: true
          }))} 
          setOpenAssignmentsModal={() => setOpenModals(prevState => ({
            ...prevState,
            projectAssignments: true
          }))}
          setOpenProjectStatusModal={() => setOpenModals(prevState => ({
            ...prevState,
            projectStatus: true
          }))}
          setOpenImportAnnotationsModal={() => setOpenModals(prevState => ({
            ...prevState,
            importAnnotations: true
          }))} 
          exportProjectAnnotations={() => exportProjectAnnotations(projectId)}
        />
        {
          (selectedFilteringOption==="All instances") ? (
            <div className="project-instances-container">
              {error ? (
                <div className="message-container">
                  <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                  <p className="message-text-main">An unexpected error occurred.</p> 
                  <p className="message-text">Please try again later.</p>
                </div>
              ) : loadingDataChunks ? (
                <div className="loading-indicator">
                  <div className="spinner"></div>
                  <span>Loading...</span>
                </div>
              ) : dataChunks.length === 0 ? (
                <div className="message-container">
                  <img src={naIcon} className="message-icon" alt="Not Available" />
                  <p className="message-text-main">No available data chunks.</p>
                </div>
              ) : (
                <div className="project-instances-list">
                  {dataChunks.map((dataChunk, dataChunkIndex) => (
                    <div className="data-chunk-sublist" key={dataChunk.id}>
                      <div className="data-chunk-card">
                        <div className="data-chunk-card-title"> Data Chunk {dataChunkIndex + 1} </div>
                        <div className="data-chunk-card-utils">
                          <button 
                            className="annotators-btn" 
                            title="Assign Annotators"
                            onClick={() => {
                              setSelectedDataChunk(dataChunk);
                              setOpenModals(prevState => ({
                                ...prevState,
                                dataChunkAssignments: true
                              }))
                            }}
                          >
                            <img src={AnnotatorsIcon} alt="Assign Annotators" />
                          </button>
                          <button
                            className="stats-btn" 
                            title="Statistics"
                            onClick={() => {
                              setSelectedDataChunk(dataChunk);
                              setSelectedDataChunkIndex(dataChunkIndex);
                              setOpenModals(prevState => ({
                                ...prevState,
                                dataChunkStatistics: true
                              }))             
                            }}
                          >
                            <img src={statsIcon} alt="Statistics"/>
                          </button>
                          <button
                            className="stats-btn" 
                            title="Export Annotations"
                            onClick={() => {
                              setSelectedDataChunk(dataChunk);
                              setSelectedDataChunkIndex(dataChunkIndex);
                              exportDataChunkAnnotations(dataChunk.id);                          
                            }}
                          >
                            <img src={DownloadIcon} alt="Export Annotations"/>
                          </button>
                          {/* <button className="view-all-btn">
                              <Link
                                className="btn-link"
                                // to={`${currentPath}/data-chunks/${dataChunk.id}/instances`}
                                state={{
                                  projectId: projectId,
                                  dataChunkId: dataChunk.id,
                                }}
                              >
                                View All
                              </Link>
                            </button> */}
                          <div className="expand-btn" onClick={() => toggleExpanded(dataChunkIndex)}>
                            <img src={expandedStates[dataChunkIndex] ? DownArrowIcon : RightArrowIcon} alt="Expand" />
                          </div>
                        </div>
                      </div>
                      {expandedStates[dataChunkIndex] && (
                        <div className="instances-cards-header">
                          <div className="fields-section">
                            <span className="header-field">Name</span>
                            <span className="header-field">File Type</span>
                            <span className="header-field">File Size</span>
                          </div>
                          <div className="empty-section"></div>
                        </div>
                      )}
                      {expandedStates[dataChunkIndex] && (
                        dataChunk.instances.map((instance, instanceIndex) => (
                          <div className="instance-card" key={instance.id}>
                            <div className="item-fields-section">
                              <span className="item-field instance-card-title">{instance.name}</span>
                              <span className="item-field">{instance.fileType}</span>
                              <span className="item-field">{instance.fileSize}</span>
                            </div>
                            <div className="item-utils-section">
                              <button
                                className="stats-btn" 
                                title="Statistics"
                                onClick={() => {
                                  setSelectedInstance(instance);
                                  setSelectedDataChunk(dataChunk);
                                  setSelectedDataChunkIndex(dataChunkIndex);
                                  setOpenModals(prevState => ({
                                    ...prevState,
                                    instanceStatistics: true
                                  }))
                                }}
                              >
                                <img src={statsIcon} alt="Statistics"/>
                              </button>
                              <button
                                className="stats-btn" 
                                title="Export Annotations"
                                onClick={() => {
                                  setSelectedDataChunk(dataChunk);
                                  setSelectedDataChunkIndex(dataChunkIndex);
                                  setSelectedInstance(instance);
                                  exportInstanceAnnotations(dataChunk.id, instance.id);                    
                                }}
                              >
                                <img src={DownloadIcon} alt="Export Annotations"/>
                              </button>
                              <button className="view-btn">
                                <Link
                                  className="btn-link"
                                  to={`${currentPath}/data-chunks/${dataChunk.id}/instances/${instance.id}`}
                                  state={{
                                    projectId: projectId,
                                    dataChunkId: dataChunk.id,
                                    instanceId: instance.id,
                                    instanceParentDatasetName: instance.parentDataset.name,
                                    instanceName: instance.name,
                                    instanceFileType: instance.fileType,
                                    instanceFileSize: instance.fileSize,
                                    instanceRelativePath: instance.relativePath
                                  }}
                                >
                                  View
                                </Link>
                              </button>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (selectedFilteringOption==="Automatic annotations") && (
            <div className="automatic-annotations-container">
              {error ? (
                <div className="message-container">
                  <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                  <p className="message-text-main">An unexpected error occurred.</p> 
                  <p className="message-text">Please try again later.</p>
                </div>
              ) : loadingDataChunks ? (
                <div className="loading-indicator">
                  <div className="spinner"></div>
                  <span>Loading...</span>
                </div>
              ) : dataChunksPerAnnotationModule.length === 0 ? (
                <div className="message-container">
                  <img src={naIcon} className="message-icon" alt="Not Available" />
                  <p className="message-text">No machine annotators assigned.</p>
                </div>
              ) : (
                <div className="annotation-modules-container">
                    <div className="dropdown">
                      <select
                          value={selectedAnnotationModuleIndex}
                          onChange={handleAnnotationModuleChange}
                      >
                          <option value="Annotation Module" disabled>Annotation Module</option>
                          {dataChunksPerAnnotationModule.map((annotationModule, annotationModuleIndex) => (
                              <option key={annotationModule._id} value={annotationModuleIndex}>
                                  {annotationModule.name}
                              </option>
                          ))}
                      </select>
                    </div>
                    <div className="annotation-modules-list" 
                      key={dataChunksPerAnnotationModule[selectedAnnotationModuleIndex]._id}
                    >
                      {dataChunksPerAnnotationModule[selectedAnnotationModuleIndex].dataChunks.map((dataChunk, dataChunkIndex) => (
                        <div className="data-chunk-container">
                          <div className="data-chunk-card">
                            <div className="data-chunk-card-title"> Data Chunk {dataChunkIndex} </div>
                            <div className="data-chunk-card-utils">
                              <div className="progress-container">
                                <div>{dataChunkPendingPercentages[dataChunkIndex]}%</div>
                                <div className="progress-bar"
                                  title={`${dataChunksPerAnnotationModule[selectedAnnotationModuleIndex].dataChunks[dataChunkIndex].pendingAnnotations} pending annotations`}
                                >
                                  <div className="completion-bar" 
                                    style={{ width: `${dataChunkPendingPercentages[dataChunkIndex] !== null ? dataChunkPendingPercentages[dataChunkIndex] : 0}%` }} 
                                  />
                                </div>
                              </div>
                              {(dataChunkPendingPercentages[dataChunkIndex] !== 100) && (
                                <button 
                                  className="repeat-annotations-btn"
                                  onClick={() => submitAllPendingAnnotations(dataChunkIndex)}
                                >
                                  <img src={ReplayIcon} title="Repeat all pending annotations"/>
                                </button>
                              )}
                              <div className="expand-btn" onClick={() => toggleExpanded(dataChunkIndex)}>
                                <img src={expandedStates[dataChunkIndex] ? DownArrowIcon : RightArrowIcon} alt="Expand" />
                              </div>
                            </div>
                          </div>
                          {expandedStates[dataChunkIndex] && (
                            <div className="instances-list">
                              <div className="instances-cards-header">
                                <div className="fields-section">
                                  <span className="header-field">Name</span>
                                  <span className="header-field">File Type</span>
                                  <span className="header-field">File Size</span>
                                </div>
                                <div className="empty-section"></div>
                              </div>
                              {dataChunk.dataChunkInstances.map((instance, instanceIndex) => (
                                <div className="instance-card" key={instance.id}>
                                <div className="item-fields-section">
                                  <span className="item-field instance-card-title">{instance.instanceInfo.name}</span>
                                  <span className="item-field">{instance.instanceInfo.fileType}</span>
                                  <span className="item-field">{instance.instanceInfo.fileSize}</span>
                                </div>
                                <div className="item-utils-section">
                                  {checkIfAnnotationMissing(dataChunkIndex, instanceIndex) ? (
                                    <button 
                                      className="repeat-annotations-btn"
                                      onClick={() => submitPendingAnnotation(dataChunkIndex, instanceIndex)}
                                    >
                                      <img src={ReplayIcon} title="Repeat pending annotation"/>
                                    </button>
                                  ) : (
                                    <input 
                                      className="pending-annotation-checkbox"
                                      type="checkbox"
                                      disabled={true}
                                      checked={true}
                                      title={"Annotation Completed"}
                                    />
                                  )}
                                  <button className="view-btn">
                                    <Link
                                      className="btn-link"
                                      to={`${currentPath}/data-chunks/${dataChunk.dataChunkId}/instances/${instance.instanceInfo._id}/automatic-annotations`}
                                      state={{
                                        annotationModuleId: dataChunksPerAnnotationModule[selectedAnnotationModuleIndex]._id,
                                        projectId: projectId,
                                        dataChunkId: dataChunk.dataChunkId,
                                        instanceId: instance.instanceInfo._id,
                                        instanceName: instance.instanceInfo.name,
                                        instanceRelativePath: instance.instanceInfo.relativePath
                                      }}
                                    >
                                      View
                                    </Link>
                                </button>
                                </div>
                              </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                </div>
              )}
            </div>
          )
        }
      </section>
    </>
  );
}

export default AdminProjectInstancesDashboard;
