import React from "react";
import { Link } from "react-router-dom";

import projectIcon from "../../images/project.png";
import coloredProjectIcon from "../../images/project-colored.png";
import datasetIcon from "../../images/dataset.png";
import coloredDatasetIcon from "../../images/dataset-colored.png";
import annotatorIcon from "../../images/annotator.png";
import coloredAnnotatorIcon from "../../images/annotator-colored.png";
import modelsIcon from "../../images/models.png";
import coloredModelsIcon from "../../images/models-colored.png";


import "./DashboardSideBar.css";

function DashboardSideBar({ selectedDashboard, onDashboardChange }) {

  const renderSidebarItem = (itemName, route, icon, coloredIcon) => (
    <li key={itemName}>
      <Link
        to={route}
        className={`side-bar-item ${selectedDashboard === itemName ? "selected" : ""}`}
        title={itemName}
        onClick={() => onDashboardChange(itemName)}
      >
        <img src={selectedDashboard === itemName ? coloredIcon : icon} alt={`${itemName} Icon`} />
      </Link>
    </li>
  );

  return (
    <div className="dashboard-side-bar">
      <ul>
        {renderSidebarItem("projects", "/admin/dashboard/projects", projectIcon, coloredProjectIcon)}
        {renderSidebarItem("datasets", "/admin/dashboard/datasets", datasetIcon, coloredDatasetIcon)}
        {renderSidebarItem("annotators", "/admin/dashboard/annotators", annotatorIcon, coloredAnnotatorIcon)}
        {renderSidebarItem("models", "/admin/dashboard/models", modelsIcon, coloredModelsIcon)}
      </ul>
    </div>
  );
}

export default DashboardSideBar;
