import { React, useState, useEffect } from "react";
import api from '../../../../axiosApi/api';

import BackgroundOverlay from "../../../BackgroundOverlay/BackgroundOverlay";
import xIcon from "../../../../images/x-icon.png"
import naIcon from "../../../../images/na.png"
import ErrorIcon from "../../../../images/error-icon.png";

import humanAnnotatorIcon from "../../../../images/human-annotator.png";
import machineAnnotatorIcon from "../../../../images/machine-annotator.png"

import "../../Modals.css"
import "../AnnotatorsModal.css"


const DataChunkAnnotatorsModal = ({ selectedProjectId, selectedDataChunkId, updateAssignments, onClose }) => {

  const [loadingUsers, setLoadingUsers] = useState(true)
  const [error, setError] = useState(false)
  const [renderedAnnotatorsCategory, setrenderedAnnotatorsCategory] = useState("assigned")
  const [assignedAnnotators, setAssignedAnnotators] = useState([])
  const [notassignedAnnotators, setNotassignedAnnotators] = useState([])
  const [pendingAssignments, setPendingAssignments] = useState([])
  const [pendingRemovals, setPendingRemovals] = useState([])


  useEffect(() => {
    setLoadingUsers(true);
    
    // Fetch assigned annotators
    api.get(`api/data-chunks/${selectedDataChunkId}/assigned-annotators`, {
      headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
    }).then((res) => {
      setAssignedAnnotators(res['data']['assignedAnnotators'])
    }).catch((error) => {
      setError(true);
      setLoadingUsers(false);  
      return
    });
    
    // Fetch not assigned annotators
    api.get(`api/data-chunks/${selectedDataChunkId}/not-assigned-annotators`, {
      headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
    }).then((res) => {
      setNotassignedAnnotators(res['data']['notAssignedAnnotators'])
      setLoadingUsers(false);  
    }).catch((error) => {
      setError(true);
      setLoadingUsers(false);  
      return
    });
  }, []);


  // Handle the assignment (or removal) button click for each user card
  const handleAssignmentButtonClick = (annotatorId) => {
    const desiredItem = {
        annotationModuleId: annotatorId,
        projectId: selectedProjectId,
        dataChunkId: selectedDataChunkId,
    };

    if (renderedAnnotatorsCategory === "assigned") {
      const exists = pendingRemovals.some(item =>
        item.annotationModuleId === desiredItem.annotationModuleId &&
        item.projectId === desiredItem.projectId &&
        item.dataChunkId === desiredItem.dataChunkId
      );

      if (exists) {
        setPendingRemovals(pendingRemovals.filter(removal =>
          removal.annotationModuleId !== desiredItem.annotationModuleId ||
          removal.projectId !== desiredItem.projectId ||
          removal.dataChunkId !== desiredItem.dataChunkId
        ));
      } else {
          setPendingRemovals([...pendingRemovals, desiredItem]);
      }
    } else if (renderedAnnotatorsCategory === "not-assigned") {
      const exists = pendingAssignments.some(item =>
        item.annotationModuleId === desiredItem.annotationModuleId &&
        item.projectId === desiredItem.projectId &&
        item.dataChunkId === desiredItem.dataChunkId
      );

      if (exists) {
        setPendingAssignments(pendingAssignments.filter(assignment =>
          assignment.annotationModuleId !== desiredItem.annotationModuleId ||
          assignment.projectId !== desiredItem.projectId ||
          assignment.dataChunkId !== desiredItem.dataChunkId
        ));
      } else {
          setPendingAssignments([...pendingAssignments, desiredItem]);
      }
    }
  }


  const isPending = (annotatorId) => {
    const objectToCheck = {
      annotationModuleId: annotatorId,
      projectId: selectedProjectId,
      dataChunkId: selectedDataChunkId
    }

    let exists = false;
    if (renderedAnnotatorsCategory === "assigned") {
      exists = pendingRemovals.some(item =>
        item.annotationModuleId === objectToCheck.annotationModuleId &&
        item.projectId === objectToCheck.projectId &&
        item.dataChunkId === objectToCheck.dataChunkId
      );
    } else if (renderedAnnotatorsCategory === "not-assigned") {
      exists = pendingAssignments.some(item =>
        item.annotationModuleId === objectToCheck.annotationModuleId &&
        item.projectId === objectToCheck.projectId &&
        item.dataChunkId === objectToCheck.dataChunkId
      );
    }

    return exists;

  }

  // Submit the updated user assignments
  const handleAssignmentsUpdate = () => {
    let updatedAnnotatorsData = []

    pendingAssignments.map((assignment) => {
      let updatedAnnotatorDocument = {
        "annotation_module_id": assignment.annotationModuleId,
        "updated_data": {
          "assigned_projects": {
            "additions": [
              {
                "project_id": assignment.projectId
              }
            ]
          },
          "assigned_data_chunks": {
            "additions": [
              {
                "data_chunk_id": assignment.dataChunkId
              }
            ]
          }
        }
      };
      updatedAnnotatorsData.push(updatedAnnotatorDocument);
    });

    pendingRemovals.map((removal) => {
      let updatedAnnotatorDocument = {
        "annotation_module_id": removal.annotationModuleId,
        "updated_data": {
          "assigned_projects": {
            "removals": [
              {
                "project_id": removal.projectId
              }
            ]
          },
          "assigned_data_chunks": {
            "removals": [
              {
                "data_chunk_id": removal.dataChunkId
              }
            ]
          }
        }
      };
      updatedAnnotatorsData.push(updatedAnnotatorDocument);
    });
    
    updateAssignments(updatedAnnotatorsData).then((submissionResult) => {
      if (submissionResult.success) {
        onClose(); 
      } else {
        console.error(submissionResult.error);
      }
    })
    .catch((error) => {
      console.error(error);
    })
    onClose();
  }

  // Clear the state of the project creation modal
  const clearState = () => {
    setLoadingUsers(false)
    setrenderedAnnotatorsCategory("assigned")
    setAssignedAnnotators([])
    setNotassignedAnnotators([])
    setPendingAssignments([])
    setPendingRemovals([])
  }

  // Close the project creation modal
  const onCloseClick = () => {
    clearState()
    onClose()
  };

  const getUsersList = () => {
    return renderedAnnotatorsCategory === 'assigned' ? assignedAnnotators : notassignedAnnotators;
  };

  return (
    <>
      <BackgroundOverlay />
      <div className="assignments-modal">
        <div className="assignments-modal-header">
          <h3>Manage Annotators</h3>
          <img 
            src={xIcon} 
            className="x-icon" 
            onClick={onCloseClick} 
            alt="Close Icon"
          />
        </div>
        <div className="assignments-modal-body">
          <div className="assignments-container">
            <div className="assignments-container-header">
              <div className="annotator-filters-section">
                <button 
                  className={`annotator-filter ${renderedAnnotatorsCategory === 'assigned' ? 'selected' : ''}`} 
                  onClick={() => setrenderedAnnotatorsCategory('assigned')}
                >
                  Assigned Annotators
                </button>
                <div className="vertical-seperator"></div>
                <button 
                  className={`annotator-filter ${renderedAnnotatorsCategory === 'not-assigned' ? 'selected' : ''}`} 
                  onClick={() => setrenderedAnnotatorsCategory('not-assigned')}
                >
                  Not Assigned Annotators
                </button>
              </div>
            </div>
            <div className="assignments-container-body">
            {error ? (
              <div className="message-container">
                <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                <p className="message-text-main">An unexpected error occurred.</p> 
                <p className="message-text">Please try again later.</p>
              </div>
            ) :loadingUsers ? (
                <div className="loading-indicator">
                  <div className="spinner"></div>
                  <span>Loading...</span>
                </div>
              ) : (
                getUsersList().length === 0 ? (
                  <div className="no-annotators-container">
                    <img src={naIcon} className="na-icon" alt="No Users" />
                    <span className="na-text">Not available annotators</span>
                  </div>
                ) : (
                  <div className="annotators-list">
                    {getUsersList().map(annotator => (
                      <div key={annotator._id} className="annotator-card">
                        <div className="annotator-info">
                            <img src={annotator.type==="HumanAnnotator" ? humanAnnotatorIcon : machineAnnotatorIcon} />
                            <div className="username">{annotator.annotatorInfo.name}</div>
                        </div>
                        <button
                          className={`assignment-btn ${isPending(annotator._id) ? 'pending' : ''}`}
                          onClick={() => handleAssignmentButtonClick(annotator._id)}
                        >
                          {renderedAnnotatorsCategory === 'assigned' ? 'Remove' : 'Assign'}
                        </button>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div className="assignments-modal-footer">
          <button 
            disabled={pendingAssignments.length === 0 && pendingRemovals.length === 0}
            className="submission-footer-btn"
            onClick={handleAssignmentsUpdate}
          > 
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};

export default DataChunkAnnotatorsModal
