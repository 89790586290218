import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import api from '../../../axiosApi/api';

import AnnotatorsUtilsBar from '../../UtilsBar/AnnotatorsUtilsBar/AnnotatorsUtilsBar';
import CreateAnnotatorModal from "../../Modals/CreateAnnotatorModal/CreateAnnotatorModal";

import "././AnnotatorsDashboard.css";
import './../Dashboard.css';
import naIcon from "../../../images/naNew.png";
import humanAnnotatorIcon from "../../../images/human-annotator.png";
import machineAnnotatorIcon from "../../../images/machine-annotator.png"
import externalAnnotatorIcon from "../../../images/external-annotator.png"
import editIcon from "../../../images/edit.png"
import deleteIcon from "../../../images/delete-filled.png"
import ErrorIcon from "../../../images/error-icon.png";

function AnnotatorsDashboard() {
    const location = useLocation();

    const [annotators, setAnnotators] = useState([])
    const [filteredAnnotators, setFilteredAnnotators] = useState([])
    const [openAnnotatorCreationModal, setOpenAnnotatorCreationModal] = useState(false)
    const [loadingAnnotators, setLoadingAnnotators] = useState(true)
    const [error, setError] = useState(false);
    const [selectedType, setSelectedType] = useState('Type')

    const fetchAnnotators = async () => {
        try {
            const annotatorsRes = await api.get( `/api/annotation-modules`,
                {
                    headers: {  
                        Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
                    }
                }
            )
            setAnnotators(annotatorsRes.data.annotationModules);
            setFilteredAnnotators(annotatorsRes.data.annotationModules);
            setSelectedType("All");

            setError(false);
        } catch (error) {
            setError(true);
            console.error("Error fetching annotators:", error);
        } finally {
            setLoadingAnnotators(false);
        }
    };

    const createAnnotator = async(annotatorName, modelId) => {
        try {
            const data = {
                'annotator_name': annotatorName,
                'model_id': modelId
            };

            const response = await api.post('/api/annotation-modules', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
                    'Content-Type': 'application/json'
                }
            });
    
            if (response.status !== 200 && response.status !== 201) {
                console.error('Unexpected response:', response);
                return false;
            }

            fetchAnnotators()
            return true;
        } catch (error) {
            if (error.response) {
                console.error('Server responded with an error:', error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up the request:', error.message);
            }
            return false;
        }
    }


    const deleteUser = async(annotatorIndex) => {
        try {
            const annotationModuleId = annotators[annotatorIndex]._id
            await api.delete(`/api/annotation-modules/${annotationModuleId}`, {
                headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') },
            });

            fetchAnnotators();
        } catch (error) {
            console.error("Error fetching data chunk statistics:", error);
            return;
        }
    }

    
    useEffect(() => {
        fetchAnnotators();
        return () => {};
    }, []);


    return (
        <>
            <CreateAnnotatorModal open={openAnnotatorCreationModal} onClose={() => setOpenAnnotatorCreationModal(false)} createAnnotator={createAnnotator}/>
            <section className="dashboard annotators-dashboard">
                <h2>Dashboards / <b>Annotators Dashboard</b></h2>
                <AnnotatorsUtilsBar 
                    allAnnotators={annotators}
                    filteredAnnotators={filteredAnnotators}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                    setFilteredAnnotators={setFilteredAnnotators}
                    openAnnotatorCreationModal={() => setOpenAnnotatorCreationModal(true)}
                />
                <div className="annotators-container">
                {error ? (
                    <div className="message-container">
                        <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                        <p className="message-text-main">An unexpected error occurred.</p> 
                        <p className="message-text">Please try again later.</p>
                    </div>
                ) : loadingAnnotators ? (
                    <div className="loading-indicator">
                    <div className="spinner"></div>
                    <span>Loading...</span>
                    </div>
                ) : filteredAnnotators.length > 0 ? (
                    <div className="annotators-list">
                        {filteredAnnotators.map((annotator, annotatorIndex) => (
                            <div className="annotator-card" key={annotator._id}>
                                <div className="info-row">
                                    <div className="annotator-info-container">
                                        <div className="user-avatar-and-username">
                                            <img 
                                                src={
                                                    annotator.type === "HumanAnnotator" 
                                                    ? humanAnnotatorIcon 
                                                    : annotator.type === "MachineAnnotator" 
                                                    ? machineAnnotatorIcon 
                                                    : annotator.type === "ExternalAnnotator" 
                                                    && externalAnnotatorIcon 
                                                }
                                            />
                                            <div className="username">{annotator.name}</div>
                                        </div>
                                        <div className="user-type-container">
                                            <span>Type: <b><i>{annotator.type}</i></b></span>
                                        </div>
                                        <div className="user-projects-container">
                                            <div className="projects-container-label">Assigned Projects:</div>
                                            {annotator.assignedProjects && annotator.assignedProjects.length > 0 ? (
                                                <div className="projects-list">
                                                    {annotator.assignedProjects.map((project, projectIndex) => (
                                                        <div className="list-item" key={project.id} title={project.name}>
                                                            {project.name[0]}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div><b><i>None</i></b></div>
                                            )}
                                        </div>
                                        {
                                            (annotator.type === "MachineAnnotator") && (
                                                <div className="model-info-container">
                                                    <div>Model Info:</div>
                                                    <div className="model-info"> 
                                                        <b><i>{annotator.model_info.name}</i></b> 
                                                        <div> Task Type: <b><i>{annotator.model_info.task_type.name}</i></b></div>
                                                    </div>
                                                </div>
                                                
                                            )
                                        }
                                    </div>
                                    <div className="annotator-utils-container">
                                        <div className="util-buttons">
                                            <button title="Delete" onClick={() => deleteUser(annotatorIndex)}>
                                                <img src={deleteIcon} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="message-container">
                    <img src={naIcon} className="message-icon empty-box-icon" alt="Not Available" />
                    <span className="message-text">No available annotators</span>
                    </div>
                )}
                </div>
            </section>
        </>
    );
}

export default AnnotatorsDashboard;
