import React, { useState, useEffect } from "react";
import { Sketch } from '@uiw/react-color';
import palletteIcon from "../../../../../images/pallette.png";
import "./LabelSelectionStage.css";

const LabelSelectionStage = ({ labels, setLabels }) => {
  const [visibleColorPicker, setVisibleColorPicker] = useState(false);
  const [enabledAddLabelButton, setEnabledAddLabelButton] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [labelColor, setLabelColor] = useState("");

  useEffect(() => {
    setEnabledAddLabelButton(labelName !== "" && labelColor !== "");
  }, [labelName, labelColor]);

  const toggleColorPicker = () => {
    setVisibleColorPicker(!visibleColorPicker);
  };

  const addLabel = () => {
    const newLabel = { name: labelName, color: labelColor };
    setLabels(prevLabels => [...prevLabels, newLabel]);
    setLabelName("");
    setLabelColor("");
  };

  const handleFileSelection = async (event) => {
    try {
      const file = event.target.files[0]; // Get the single selected file
      if (!file || !file.name.endsWith('.txt')) {
        console.error('No valid .txt file selected');
        return;
      }
  
      const reader = new FileReader();
  
      // When the file is successfully read
      reader.onload = (e) => {
        const fileContent = e.target.result;
        const lines = fileContent.split('\n')
                                  .map(line => line.trim())
                                  .filter(line => line !== '');
        const colorSet = new Set();
        const colorObjects = lines.map((line) => {
          const labelName = line;
  
          let labelColor;
          do {
            labelColor = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`; 
          } while (colorSet.has(labelColor));
  
          colorSet.add(labelColor);
  
          return { name: labelName, color: labelColor };
        });
  
        setLabels(colorObjects);
      };
  
      reader.readAsText(file);
    } catch (error) {
      console.error('Error selecting or reading file:', error);
    }
  };

  const removeLabel = (index) => {
    const updatedLabels = labels.filter((_, idx) => idx !== index);
    setLabels(updatedLabels);
  };

  return (
    <div className="label-selection-container">
      <div className="label-utils-section">
        <h5>Select Labels</h5>
        <div className="label-utils">
          <input
            type="text"
            id="label-name-input"
            placeholder="Enter label name"
            onChange={(e) => setLabelName(e.target.value)}
            value={labelName}
            aria-describedby="uidnote"
            autoComplete="off"
          />
          <button className="color-button" onClick={toggleColorPicker}>
            <img src={palletteIcon} alt="palletteIcon" />
          </button>
          {visibleColorPicker && (
            <Sketch
              className="color-picker"
              color={labelColor}
              onChange={(color) => setLabelColor(color.hex)}
            />
          )}
          <button
            className="add-button"
            onClick={addLabel}
            disabled={!enabledAddLabelButton}
          >
            Add
          </button>

          <span>or</span>

          <label htmlFor="fileInput" className="import-file-txt">
            <span> Import from .txt file</span>
          </label>
          
          <input 
            type="file" 
            id="fileInput" 
            style={{ display: 'none' }} 
            accept=".txt"  // Only allow .txt files
            onChange={handleFileSelection} 
          />
        </div>
      </div>
      <div className="labels-list-section">
        {labels.length === 0 ? (
          <span className="no-labels-message">Add labels.</span>
        ) : (
          <div className="labels-list">
            {labels.map((label, index) => (
              <div className="labels-list-item" key={index}>
                <div className="label-info">
                  <span>{label.name}</span>
                  <div className="label-color-box" style={{ backgroundColor: label.color }}></div>
                </div>
                <button className="remove-label-button" onClick={() => removeLabel(index)}>
                  Remove
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LabelSelectionStage;
