import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import SignInPage from './pages/AuthenticationPage/SignInPage';
import SignUpPage from './pages/AuthenticationPage/SignUpPage';
import Header from './components/Header/Header';
import AdminDashboardPage from './pages/DashboardPage/AdminDashboardPage';
import AnnotatorDashboardPage from './pages/DashboardPage/AnnotatorDashboardPage';
import RequireAdmin from "./components/RequireAdminComponent/RequireAdmin";
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import AboutPage from './pages/AboutPage/AboutPage';


function App() {
  const location = useLocation();

  useEffect(() => {
    document.title = "Annotation Pipeline";
    return () => {
      document.title = "Annotation Pipeline";
    };
  }, []);

  const shouldRenderHeader = !['/signin', '/'].includes(location.pathname);

  const renderHeader = () => {
    if (shouldRenderHeader) {
      return <Header />;
    }
    return null;
  };

  return (
    <div className="app-container">
      {renderHeader()}
      <Routes>
        <Route path="/" element={<SignUpPage />} />
        <Route path="/signin" element={<SignInPage />} />

        <Route
          path="/admin/dashboard/*"
          element={<RequireAdmin><AdminDashboardPage/></RequireAdmin>
          }
        />
        <Route
          path="/annotator/dashboard/*"
          element={<AnnotatorDashboardPage />}
        />
        <Route
          path="/annotator/about/*"
          element={<AboutPage />}
        />
        <Route
          path="/admin/about/*"
          element={<AboutPage />}
        />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
