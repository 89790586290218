import React, { useState, useEffect } from "react";
import filterIcon from "../../../images/filter-dark.png";
import uploadIcon from "../../../images/upload-icon.png";
import sortIcon from "../../../images/sort-dark.png";
import sortDownIcon from "../../../images/sort-down-dark.png";
import "../UtilsBar.css";

function ModelsUtilsBar({ openModelUploadModal }) {
    useEffect(() => {
        
    }, []);

    return (
        <div className="utils-container">
            <div className='utils-section' >
            
            </div>
            <button className="upload-model-btn" title="Add a Model" onClick={() => openModelUploadModal()}>
                <p className="upload-btn-text">Add</p>
                <img src={uploadIcon} className="upload-btn-icon" alt="UploadIcon" />
            </button>
        </div>
    );
}

export default ModelsUtilsBar;
