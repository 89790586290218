import React, { useState, useEffect } from "react";
import JSZip from 'jszip';
import api from '../../../axiosApi/api';

import xIcon from "../../../images/x-icon.png";
import uploadingGif from "../../../images/uploading.gif";
import errorIcon from "../../../images/error-icon.png";
import ModelIcon from "../../../images/model.png";
import naIcon from "../../../images/naNew.png";

import "../Modals.css";
import "./CreateAnnotatorModal.css";
import BackgroundOverlay from '../../BackgroundOverlay/BackgroundOverlay';

const CreateAnnotatorModal = ({ open, onClose, createAnnotator }) => {
    const [availableModels, setAvailableModels] = useState([]);
    const [filteredModels, setFilteredModels] = useState([]);
    const [selectedModelIndex, setSelectedModelIndex] = useState(undefined);
    const [availableModalities, setAvailableModalities] = useState([]);
    const [availableTaskTypes, setAvailableTaskTypes] = useState([]);
    const [selectedModalityIndex, setSelectedModalityIndex] = useState(undefined);
    const [selectedTaskTypeIndex, setSelectedTaskTypeIndex] = useState(undefined);
    const [dropdownTaskTypes, setDropdownTaskTypes] = useState([]);
    const [annotatorName, setAnnotatorName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [error, setError] = useState(false);

    // Fetch models, modalities, and task types
    useEffect(() => {
        api.get('/api/models', {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
        }).then((res) => {
            setAvailableModels(res.data.models);
            setFilteredModels(res.data.models);
        }).catch((error) => {
            console.log(error);
        });

        api.get('/api/modalities', {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
        }).then((res) => {
            const modalities = res.data.modalities;
            if (modalities.length > 0) {
                setAvailableModalities(modalities);
                setSelectedModalityIndex(0);
            }
        }).catch((error) => {
            console.log(error);
        });

        api.get('/api/task-types', {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
        }).then((res) => {
            const taskTypes = res.data.task_types;
            if (taskTypes.length > 0) {
                setAvailableTaskTypes(taskTypes);
                setSelectedTaskTypeIndex(0);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    // Filter available task types based on selected modality
    useEffect(() => {
        if (availableModalities.length > 0 && availableTaskTypes.length > 0 && selectedModalityIndex !== undefined) {
            setDropdownTaskTypes(
                availableTaskTypes.filter(taskType => taskType.modality === availableModalities[selectedModalityIndex]?.name)
            );
            setSelectedTaskTypeIndex(0);
        }
    }, [selectedModalityIndex, availableModalities, availableTaskTypes]);

    // Filter available models based on selected task type
    useEffect(() => {
        if (dropdownTaskTypes.length > 0 && selectedTaskTypeIndex !== undefined) {
            const filteredModels = availableModels.filter(
                model => model.task_type.name === dropdownTaskTypes[selectedTaskTypeIndex]?.name
            );
            setFilteredModels(filteredModels);
            setSelectedModelIndex(undefined); // Reset selected model index
        }
    }, [selectedTaskTypeIndex, availableModels, dropdownTaskTypes]);

    // Check if creation button should be disabled
    const checkDisabled = () => {
        const nameIsEmpty = annotatorName === undefined || annotatorName.trim() === "";
        const modalityIsNotSelected = selectedModalityIndex === undefined;
        const taskTypeIsNotSelected = selectedTaskTypeIndex === undefined;
        const modelIsNotSelected = selectedModelIndex === undefined;
    
        return nameIsEmpty || modalityIsNotSelected || taskTypeIsNotSelected || modelIsNotSelected;
    };

    // Handle machine annotator creation
    const handleCreation = async () => {
        setCreating(true);

        const name = annotatorName;
        const modelId = filteredModels[selectedModelIndex]._id;
        
        const uploadSuccess = await createAnnotator(name, modelId);

        if (!uploadSuccess) {
            setError(true);
            setCreating(false); // Stop creation process, but don't clear state
        } else {
            clearState();
            onClose();
        }
    };

    // Clear modal state
    const clearState = () => {
        setSelectedModelIndex(undefined);
        setSelectedModalityIndex(undefined);
        setSelectedTaskTypeIndex(undefined);
        setAnnotatorName("");
        setIsLoading(false);
        setCreating(false);
        setError(false);
    };

    // Handle modal close
    const onCloseClick = () => {
        clearState();
        onClose();
    };

    if (!open) return null;

    return (
        <>
        <BackgroundOverlay />
        <div className="annotator-creation-modal modal">
            <div className="modal-header">
                <h3>Create Machine Annotator</h3>
                <img
                    src={xIcon}
                    className={`x-icon ${creating ? 'disabled' : ''}`}
                    onClick={!creating ? onCloseClick : null}
                    alt="Close"
                />
            </div>
            <div className="modal-body annotator-creation-modal-body">
                {isLoading ? (
                    <div className="loading-indicator">
                        <div className="spinner"></div>
                        <span>Loading...</span>
                    </div>
                ) : creating ? (
                    <div className="uploading-indicator">
                        <img src={uploadingGif} alt="Uploading"/>
                        <div>Model is being uploaded. Please wait ...</div>
                    </div>
                ) : error ? (
                    <div className="message-container">
                        <img src={errorIcon} className="message-icon" alt="Error icon" />
                        <p className="message-text-main">An unexpected error occurred.</p> 
                        <p className="message-text">Please try again later.</p>
                    </div>
                ) : (
                    <div className="annotator-details-container">
                        <h5>Machine Annotator Details</h5> 
                        <div className="annotator-name-container">
                            <h6>Annotator name</h6>
                            <input
                                type="text"
                                id="folder-name-input"
                                placeholder={annotatorName || "Enter annotator name"}
                                onChange={(e) => setAnnotatorName(e.target.value)}
                                value={annotatorName || ""}
                                aria-describedby="uidnote"
                                autoComplete="off"
                            />
                        </div>
                        <div className="models-list-container">
                            <h6>Available Models</h6>
                            <div className="models-filtering-utils">
                                <div>
                                    <div className="dropdown">
                                        <select
                                            id="model-modality"
                                            value={selectedModalityIndex}
                                            onChange={(event) => setSelectedModalityIndex(event.target.value)}
                                        >
                                            <option value="" disabled>Modality</option>
                                            {availableModalities.map((modality, index) => (
                                                <option key={index} value={index}>
                                                    {modality.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <div className="dropdown">
                                        <select
                                            id="model-task-type"
                                            value={selectedTaskTypeIndex}
                                            onChange={(event) => setSelectedTaskTypeIndex(event.target.value)}
                                        >
                                            <option value="" disabled>Task Type</option>
                                            {dropdownTaskTypes.map((task, index) => (
                                                <option key={index} value={index}>
                                                    {task.description}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="models-list">
                                {filteredModels.length > 0 ? (
                                    filteredModels.map((model, index) => (
                                        <div key={model.id} className="model-card">
                                            <div>
                                                <img src={ModelIcon} alt="Model icon"/>
                                                <span className="card-title" title={model.name}>
                                                    {model.name}
                                                </span>
                                            </div>
                                            <button
                                                className={`select-model-btn ${selectedModelIndex === index ? 'selected' : ''}`}
                                                onClick={() => setSelectedModelIndex(index)}
                                            >
                                                Select
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <div className="message-container">
                                        <img src={naIcon} className="message-icon empty-box-icon" alt="NA Icon"/>
                                        <span className="message-text">No available models.</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="annotator-creation-footer modal-footer">
                <button
                    className="submission-footer-btn"
                    disabled={checkDisabled()}
                    onClick={handleCreation}
                >
                    Create
                </button>
            </div>
        </div>
        </>
    );
};

export default CreateAnnotatorModal;
