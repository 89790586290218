import React, { useState } from "react";
import api from '../../../axiosApi/api';

import xIcon from "../../../images/x-icon.png";

import "../Modals.css";
import "./ProjectStatusModal.css";
import BackgroundOverlay from '../../BackgroundOverlay/BackgroundOverlay'


const ProjectStatusModal = ({ onClose, projectId, projectStatus, fetchProjectStatus }) => {

    const updateStatus = async () => {
        var updatedStatus;
        if(projectStatus==="Active")
            updatedStatus = "Inactive";
        else if(projectStatus==="Inactive")
            updatedStatus = "Active";

        try {
            const res = await api.put(`/api/projects/${projectId}`, 
                {
                    status: updatedStatus 
                },
                {
                    headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
                }
            );

            if(res.status === 200){
                fetchProjectStatus()
                onClose()
            }
        } catch (error) {
            console.error("Error updating project:", error);
        }
    };

    const onCloseClick = () => {
        onClose();
    };

    return (
    <>
        <BackgroundOverlay/>
        <div className="modal project-status-modal">
            <div className="modal-header project-status-modal-header">
                <h3>Update Project Status</h3>
                <img src={xIcon} className="x-icon" onClick={onCloseClick} alt="xIcon" />
            </div>

            <div className="modal-body project-status-modal-body">
                <div className="update-options-container">
                    <div className="update-message">Would you like to update the project status to: {(projectStatus==="Active")?"'Inactive'":"'Active'"}?</div>
                    <div className="buttons-container">
                        <button className="btn cancel-btn" onClick={() => onClose()}>Cancel</button>
                        <button className="btn update-btn" onClick={() => updateStatus()}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default ProjectStatusModal;
