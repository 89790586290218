import React, { useState, useEffect } from "react";
import api from '../../../../../axiosApi/api';

import "./AssistedAnnotationsStage.css";
import ModelIcon from "../../../../../images/model.png"
import naIcon from "../../../../../images/naNew.png";
import ErrorIcon from "../../../../../images/error-icon.png";


const AssistedAnnotationsStage = ({selectedTaskType, setSelectedAssistanceModel, enabledAssistance, setEnabledAssistance}) => {
    const [availableModels, setAvailableModels] = useState([]);
    const [selectedModelIndex, setSelectedModelIndex] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false);
        setIsLoading(true);
        api.get('/api/models', {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
        }).then((res) => {
            const fetchedModels = res.data.models
            const filteredModels = fetchedModels.filter(model => model['task_type']['name'] === selectedTaskType["name"]);
            setAvailableModels(filteredModels);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
            setError(true);
        });
    }, []);

    return (
        <div className="model-config-container">
            <h5>Assisted Annotations Configuration</h5>
            <div className="model-config-section">
                <div className="enable-assistance-switch-container">
                    <span className="label">Enable assited annotations? (Optional)</span>
                    <div 
                        className={`switch ${enabledAssistance ? 'active' : ''}`} 
                        onClick={() => setEnabledAssistance(!enabledAssistance)}
                        title={enabledAssistance?"Yes":"No"}
                    >
                        <div className="slider"></div>
                    </div>
                </div>
                <div className="models-list-header-txt">Available Models</div>
                <div className="models-list">
                    {!enabledAssistance && <div className="models-list-overlay"/>}
                    {isLoading ? (
                            <div className="loading-indicator">
                                <div className="spinner"></div>
                                <span>Loading...</span>
                            </div>
                        ) : error ? (
                            <div className="message-container">
                                <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                                <p className="message-text-main">An unexpected error occurred.</p> 
                                <p className="message-text">Please try again later.</p>
                            </div>
                        ) : (availableModels && availableModels.length > 0) ? (
                            availableModels.map((model, index) => (
                                <div key={model.id} className="model-card">
                                    <div>
                                        <img src={ModelIcon} />
                                        <span className="card-title" title={model.name}>
                                            {model.name}
                                        </span>
                                    </div>
                                    <button
                                        className={`select-model-btn ${selectedModelIndex===index ? 'selected' : ''}`}
                                        onClick={() => {
                                            setSelectedModelIndex(index);
                                            setSelectedAssistanceModel(model);
                                        }}
                                    >
                                        Select
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className="message-container">
                                <img src={naIcon} className="message-icon empty-box-icon" alt="NA Icon"/>
                                <span className="message-text">No available models.</span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default AssistedAnnotationsStage;
