import React, { useState, useEffect } from "react";
import { initializeLabelStudio } from "../GroundTruthLabelStudioConfig.js";
import JSZip from 'jszip';

import api from '../../../../axiosApi/api';
import BackgroundOverlay from "../../../BackgroundOverlay/BackgroundOverlay";

import xIcon from "../../../../images/x-icon.png"
import naIcon from "../../../../images/naNew.png";

import "../../Modals.css"
import "../StatisticsModal.css"
import "./InstanceStatisticsModal.css"


const InstanceStatisticsModal = ({ taskType, dataChunkId, instanceId, instanceName, instanceRelativePath, 
                                    taskView, annotationType, projectModality, projectLabels, onClose }) => {
 
  const [loadingStatistics, setLoadingStatistics] = useState(true)

  const [annotationProgress, setAnnotationProgress] = useState(null)
  const [finishedAnnotators, setFinishedAnnotators] = useState([])
  const [pendingAnnotators, setPendingAnnotators] = useState([])
  const [avgAnnotationTime, setAvgAnnotationTime] = useState(null)
  const [stdAnnotationTime, setStdAnnotationTime] = useState(null)
  const [annotationsCharts, setAnnotationsCharts] = useState([])
  const [groundTruthAnnotations, setGroundTruthAnnotations] = useState([]);
  const [selectedSimilarityMetric, setSelectedSimilarityMetric] = useState('Similarity metric');
  const [selectedSimilarityThreshold, setSelectedSimilarityThreshold] = useState('0.5');
  const [JSONFile, setJSONFile] = useState()

  const [currentIndex, setCurrentIndex] = useState(0);

  // Fetch instance statistics when page loads
  useEffect(() => {
    fetchInstanceStatistics();
  }, []);
  
  // Handle view of instance agreement charts
  const nextChart = () => {
    if(annotationsCharts !== null)
      setCurrentIndex((prevIndex) => (prevIndex + 1) % annotationsCharts.length);
  };

  const prevChart = () => {
    if(annotationsCharts !== null)
      setCurrentIndex((prevIndex) => (prevIndex - 1 + annotationsCharts.length) % annotationsCharts.length);
  };

  // Handle change of ground truth estimation metric
  const handleSimilarityMetricChange = (event) => {
    setSelectedSimilarityMetric(event.target.value);
    fetchInstanceStatistics();
  };

  // Handle change of ground truth estimation metric threshold
  const handleThresholdValueChange = (e) => {
    let newValue = e.target.value;

    if (newValue === '' || (/^\d*\.?\d*$/.test(newValue) && newValue <= 1)) {
      if (newValue.includes('.')) {
        const [integer, decimal] = newValue.split('.');
        if (decimal.length > 2) {
          return;
        }
      }
      setSelectedSimilarityThreshold(newValue);
    }
  };

  // Handle accepted values for ground truth estimation metric threshold
  const handleThresholdBlur = () => {
    if (selectedSimilarityThreshold !== '') {
      const numericValue = parseFloat(selectedSimilarityThreshold);
      if (numericValue > 1) {
        setSelectedSimilarityThreshold('1.00');
      } else {
        setSelectedSimilarityThreshold(numericValue.toFixed(2));
      }
      fetchInstanceStatistics();
    }
  };

  // Configure styling of Ground Truth estimation section
  const configureGroundTruthStyle = () => {
    if (taskType === "RectangleLabels"){
      import("../LabelStudioStyles/GroundTruthRectangleLabels.css");
      return("ground-truth-rectangle-labels")
    } 
  }

  // Fetch instance statistics
  const fetchInstanceStatistics = async () => {
    try {
      setLoadingStatistics(true);

      let params_dict = {
        projectType: taskType,
        annotationType: annotationType
      };
      
      if (["RectangleLabels"].includes(taskType)) {
        params_dict["groundTruthSettings"] = {
          similarity_metric: selectedSimilarityMetric === "Similarity metric" ? "IoU" : selectedSimilarityMetric,
          similarity_threshold: selectedSimilarityThreshold
        };
      }

      const res = await api.get(`/api/data-chunks/${dataChunkId}/instances/${instanceId}/stats`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') },
        params: params_dict
      });

      setAnnotationProgress(res.data.annotationProgress);
      setFinishedAnnotators(res.data.annotators.finishedAnnotators);
      setPendingAnnotators(res.data.annotators.pendingAnnotators);
      setAvgAnnotationTime(res.data.avgAnnotationTime);
      setStdAnnotationTime(res.data.stdAnnotationTime);
      setAnnotationsCharts(res.data.agreementCharts);
      
      if (taskType === "RectangleLabels") {
        setGroundTruthAnnotations(res.data.groundTruthAnnotations);
        initializeLabelStudio(
          taskView,
          projectModality, 
          projectLabels, 
          annotationType,
          instanceRelativePath,
          res.data.groundTruthAnnotations
        );
      }
      setJSONFile(res.data.jsonFile);
      setLoadingStatistics(false);
    } catch (error) {
      console.error("Error fetching instance statistics:", error);
      setLoadingStatistics(false);
    }
  };

  // Export statistics zip
  const exportStats = async () => {
    if (!JSONFile && annotationsCharts.length === 0) {
      console.error("No JSON file or charts available to export.");
      return;
    }
  
    try {
      const jsonFileContent = atob(JSONFile);
  
      const jsonData = JSON.parse(jsonFileContent);
      const jsonString = JSON.stringify(jsonData, null, 2);
  
      const utf8Encoder = new TextEncoder();
      const utf8Content = utf8Encoder.encode(jsonString);
  
      const zip = new JSZip();
  
      zip.file(`${instanceName}_statistics.json`, utf8Content);
  
      annotationsCharts.forEach((chartBase64, index) => {
        const chartBinary = Uint8Array.from(atob(chartBase64), c => c.charCodeAt(0));
        zip.file(`chart_${index + 1}.png`, chartBinary);
      });
  
      const zipContent = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(zipContent);
  
      const a = document.createElement("a");
      a.href = url;
      a.download = `statistics.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting JSON file or charts:", error);
    }
  };


  return (
    <>
      <BackgroundOverlay></BackgroundOverlay>
      <div className="modal statistics-modal">
        <div className="modal-header">
          <h3>Statistics</h3>
          <img src={xIcon} className="x-icon" onClick={onClose} alt = "xIcon"></img>
        </div>
        <div className="modal-body statistics-body">
          
            {loadingStatistics ? (
              <div className="loading-indicator">
                <div className="spinner"></div>
                <span>Loading...</span>
              </div>
            ) : (
              <div className="instance-statistics-content">
                <div className="instance-name"> {instanceName}</div>
                <div className="instance-stats">
                  <div className="instance-general-stats">
                    <div className="stats-block">
                      <div className="stats-block-header">Annotation progress</div>
                      <div className="stats-block-body">
                        <div>Progress: {annotationProgress ? annotationProgress : "?"}%</div>
                        <div className="progress-bar">
                          <div className="completion-bar" style={{width: `${annotationProgress ? annotationProgress : 0}%`}}/>
                        </div>
                        <div>
                            <div>Annotated by:</div>
                            {finishedAnnotators.length > 0 &&
                              <div className="annotators-list">
                                {finishedAnnotators.map((annotator, annotatorIndex) => (
                                  <div className="annotator-icon" key={annotator.id} title={annotator.name}>
                                    {annotator.name[0]}
                                  </div>
                                ))}
                              </div>
                            }
                        </div>
                        <div>
                          <div>Pending:</div>
                          {pendingAnnotators.length > 0 &&
                            <div className="annotators-list">
                              {pendingAnnotators.map((annotator, annotatorIndex) => (
                                <div className="annotator-icon" key={annotator.id} title={annotator.name}>
                                  {annotator.name[0]}
                                </div>
                              ))}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="stats-block">
                      <div className="stats-block-header">Annotation time</div>
                      <div className="stats-block-body">
                        <div>Avg. annotation time: {avgAnnotationTime ? avgAnnotationTime : "?"} sec.</div>
                        <div>Std. annotation time: {stdAnnotationTime ? stdAnnotationTime : "?" } sec.</div>
                      </div>
                    </div>
                  </div>
                  {["RectangleLabels"].includes(taskType) && (
                    <div className="ground-truth-section">
                      <div className="ground-truth-header">Ground Truth</div>
                      <div className="ground-truth-bar">
                        <div className="dropdown">
                          <label htmlFor="distance-metric">Choose a metric: </label>
                          <select
                            id="distance-metric"
                            value={selectedSimilarityMetric}
                            onChange={handleSimilarityMetricChange}
                          >
                            <option value="Distance metric" disabled>
                              Distance metric
                            </option>
                            <option value="IoU">IoU</option>
                            <option value="GIoU">GIoU</option>
                          </select>
                        </div>
                        <div className="threshold-value">
                          <label htmlFor="distance-metric">Type a threshold value: </label>
                          <input
                            id="threshold-value"
                            type="text"
                            value={selectedSimilarityThreshold}
                            onChange={handleThresholdValueChange}
                            onBlur={handleThresholdBlur}
                            placeholder="Values between 0 and 1"
                            title="Values between 0 and 1"
                            className="text-input"
                          />
                        </div>
                      </div>
                      <div 
                        id="label-studio" 
                        className={configureGroundTruthStyle()}
                      ></div>
                    </div>
                  )}
                  <div className="annotation-charts">
                    {annotationsCharts.length > 0 ? (
                      <div className="annotation-chart">
                        <img
                          alt={`Chart ${currentIndex}`}
                          src={`data:image/png;base64, ${annotationsCharts[currentIndex]}`}
                        />
                      </div> 
                    ) : (
                      <div className="not-available-container">
                        <img src={naIcon} className="na-icon" alt="Not Available" />
                        <span className="na-text">No available charts</span>
                      </div>
                    )}
                    <div className="chart-controls">
                      <button onClick={prevChart} disabled={annotationsCharts.length <= 1 || currentIndex === 0}>
                        Previous
                      </button>
                      <button onClick={nextChart} disabled={annotationsCharts.length <= 1 || currentIndex === annotationsCharts.length - 1}>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
        <div className="modal-footer statistics-footer">
          <button 
            disabled={false}
            className="submission-footer-btn"
            onClick={exportStats}
          > 
            Export statistics
          </button>
        </div>
      </div>
    </>
  )
}
export default InstanceStatisticsModal