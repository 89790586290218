import React, { useState, useEffect } from "react";
import xIcon from "../../../images/x-icon.png";
import uploadIcon from "../../../images/upload-file.png";
import fileIcon from "../../../images/file.png";
import errorIcon from "../../../images/error.png"
import tickIcon from "../../../images/tick.png"
import dashIcon from "../../../images/dash.png"


import "../Modals.css";
import "./ImportAnnotationsModal.css";
import BackgroundOverlay from '../../BackgroundOverlay/BackgroundOverlay';

const ImportAnnotationsModal = ({ projectTaskType, importAnnotations, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [jsonData, setJsonData] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [importStatus, setImportStatus] = useState([]);


    const configureImportStatusIcon = (dictIndex) => {
        console.log(importStatus)
        if(importStatus[dictIndex]['Status']==="Failed") return errorIcon
        if(importStatus[dictIndex]['Status']==="Success") return tickIcon
        return dashIcon
    }

    const handleFileSelection = async (event) => {
        setIsLoading(true);
        try {
            const files = event.target.files;
            if (!files.length) {
                console.error('No files selected');
                setIsLoading(false);
                return;
            }

            // Extract the folder name from the first file's webkitRelativePath
            const file = files[0];
            const filePath = file.webkitRelativePath;
            const relativePath = file.webkitRelativePath || file.name;
        
            let fileName;
            const dotSplitTokens = file.name.split('.');
            if (file.name.includes("."))
                fileName = dotSplitTokens.length === 2 ? dotSplitTokens[0] : dotSplitTokens.slice(0, -1).join(".");
            else
                fileName = file.name;

            setSelectedFileName(fileName)

            let fileType;
            if (fileName === "Dockerfile")
                fileType = "Dockerfile";
            else
                fileType = dotSplitTokens.length === 2 ? dotSplitTokens[1] : dotSplitTokens[dotSplitTokens.length - 1];
        
            // Check if it's a JSON file
            if (fileType.toLowerCase() === 'json') {
                const reader = new FileReader();

                reader.onload = (e) => {
                    try {
                        const fileContent = e.target.result;
                        const parsedJson = JSON.parse(fileContent)
                        setJsonData(parsedJson);
                        setImportStatus(new Array(parsedJson.length).fill({"Status":"na", "Message":"Pending..."}));
                    } catch (parseError) {
                        console.error('Error parsing JSON file:', parseError);
                    }
                };

                reader.onerror = (e) => {
                    console.error('Error reading file:', e);
                };

                reader.readAsText(file);
            }
        } catch (error) {
            console.error('Error selecting file:', error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };


    const getFormattedAnnotation = (dictIndex) => {
        const data = jsonData[dictIndex] || {};
    
        if (projectTaskType === "ImageClassification") {
            return data.label || "<Error Parsing Annotation>";
        } else if (projectTaskType === "ImageCaptioning") {
            return data.captions ? data.captions.join(',') : "<Error Parsing Annotation>";
        } else if (projectTaskType === "PolygonLabels") {
            return "<Polygon Keypoints>";
        } else if (projectTaskType === "RectangleLabels") {
            return "<Bounding Boxes xywh>";
        } else if (projectTaskType === "AudioClassification") {
            return data.label || "<Error Parsing Annotation>";
        } else if (projectTaskType === "AudioCaptioning") {
            return data.captions ? data.captions.join(',') : "<Error Parsing Annotation>";
        } else if (projectTaskType === "SegmentsAudioCaptioning") {
            return "<Captions per Segment>";
        } else if (projectTaskType === "SegmentsAudioClassification") {
            return "<Label per Segment>";
        }
    }

    
      // Function to delete a row
    const deleteRow = (dictIndex) => {
        const updatedDicts = [...jsonData];
        updatedDicts.splice(dictIndex, 1);
        setJsonData(updatedDicts);
    };

    
    const handleImport = async() => {
        const response = await importAnnotations(jsonData, projectTaskType);
        console.log(response)
        const annotationsStatus = response.annotationsStatus;
        setImportStatus(annotationsStatus);
    }


    const clearContent = () => {
        setJsonData([]);
        setSelectedFileName('');
        setImportStatus([]);
    }


    const onCloseClick = () => {
        onClose();
    };

    return (
        <>
            <BackgroundOverlay />
            <div className="modal import-annotations-modal">
                <div className="modal-header import-annotations-modal-header">
                    <h3>Import Annotations</h3>
                    <img src={xIcon} className="x-icon" onClick={onCloseClick} alt="xIcon" />
                </div>
                <div className="modal-body import-annotations-modal-body">
                    {isLoading ? (
                        <div className="loading-indicator">
                        <div className="spinner"></div>
                        <span>Loading...</span>
                        </div>
                    )  : (jsonData.length===0) ? (
                        <div className="file-selection-section">
                            <div className="file-selection-body">
                                <label htmlFor="fileInput" className="file-input-label">
                                    <img src={uploadIcon} alt="Upload Icon" />
                                    <span>Select the .JSON file that contains the annotations</span>
                                </label>
                                <input 
                                    type="file" 
                                    id="fileInput" 
                                    style={{ display: 'none' }} 
                                    onChange={handleFileSelection} 
                                    accept=".json" 
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="annotations-preview-section">
                            <div className="annotations-preview-header">
                                <div>
                                    <h6>Imported annotations</h6>
                                    <div className="json-info-container">
                                        <div className="file-img">
                                            <img src={fileIcon} alt="file-icon"/>
                                        </div>
                                        <div className="json-info">
                                            <div className="json-name">{selectedFileName}.json</div>
                                            <div className="json-files-num">{jsonData.length} annotations</div>
                                        </div>
                                    </div>
                                </div>
                                <button className="remove-instances-btn" onClick={() => clearContent()}>
                                    Clear
                                </button>
                            </div>
                            <div className="annotations-preview-body">
                                <table className="annotations-table">
                                    <thead>
                                        <tr>
                                            <th>Annotator ID</th>
                                            <th>Instance Name</th>
                                            <th>Annotation</th>
                                            <th>Import Status</th>
                                            <th className="delete-column"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jsonData.map((dict, index) => (
                                            <tr key={index}>
                                                    <td>{dict.annotator_id}</td>
                                                    <td>{dict.instance_name}</td>
                                                    <td>{getFormattedAnnotation(index)}</td>
                                                    <td>
                                                        <img 
                                                            src={configureImportStatusIcon(index)}
                                                            title={importStatus[index]['Message']}
                                                        />
                                                    </td>
                                                    <td className="delete-column">
                                                        <button className="delete-annotation-btn" onClick={() => deleteRow(index)}>Delete</button>
                                                    </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
                <div className="modal-footer import-annotations-modal-footer">
                    <button
                        className="submission-footer-btn"
                        disabled={isLoading}
                        onClick={() => handleImport()}
                    >Import</button>
                </div>
            </div>
        </>
    );
};

export default ImportAnnotationsModal;
