import axios from 'axios';

//const baseURL = process.env.REACT_APP_API_URL || ' http://127.0.0.1:5000';
const baseURL = 'https://audio-tool-api.annotation.scify.org'
const timeout = 900000;

const api = axios.create({
    baseURL,
    timeout,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default api;