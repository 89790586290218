import React, { useRef, useState, useEffect } from "react";
import api from '../../axiosApi/api';

import logoImage from '../../images/logo.png';
import tickIcon from '../../images/tick.png';
import errorIcon from '../../images/error.png';
import infoIcon from '../../images/info.png';
import invisibilityIcon from '../../images/invisibility.png';
import visibilityIcon from '../../images/visibility.png';

import { Link } from 'react-router-dom';
import './AuthPages.css';

const SignUpPage = () => {
    const userRef = useRef();
    const passwordRef = useRef();
    const matchPasswordRef = useRef();
    const usernameInfoRef = useRef(null);
    const passwordInfoRef = useRef(null);
    const errRef = useRef(null);
    const infoPopupTimeoutRef = useRef(null);
    const errorPopupTimeoutRef = useRef(null);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [matchPassword, setMatchPassword] = useState('');
    const [validUsername, setValidUsername] = useState(true);
    const [validPassword, setValidPassword] = useState(true);
    const [validMatchPassword, setValidMatchPassword] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [matchPasswordVisible, setMatchPasswordVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [usernameInfoIsVisible, setUsernameInfoIsVisible] = useState(false);
    const [passwordInfoIsVisible, setPasswordInfoIsVisible] = useState(false);
    const [usernameInfoPosition, setUsernameInfoPosition] = useState({ top: 0, left: 0 });
    const [passwordInfoPosition, setPasswordInfoPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        if (usernameInfoRef.current && passwordInfoRef.current) {
            const usernameInfoRect = usernameInfoRef.current.getBoundingClientRect();
            const passwordInfoRect = passwordInfoRef.current.getBoundingClientRect();

            setUsernameInfoPosition({
                top: usernameInfoRect.top + window.scrollY,
                left: usernameInfoRect.left
            });

            setPasswordInfoPosition({
                top: passwordInfoRect.top + window.scrollY,
                left: passwordInfoRect.left
            });
        }
    }, [usernameInfoIsVisible, passwordInfoIsVisible]);

    const togglePasswordVisibility = () => {
        setPasswordVisible(prev => !prev);
    };

    const toggleMatchPasswordVisibility = () => {
        setMatchPasswordVisible(prev => !prev);
    };

    const popupUsernameInfo = () => {
        setPasswordInfoIsVisible(false);
        setUsernameInfoIsVisible(true);

        clearTimeout(infoPopupTimeoutRef.current);
        infoPopupTimeoutRef.current = setTimeout(() => {
            setUsernameInfoIsVisible(false);
        }, 3000);
    };

    const popupPasswordInfo = () => {
        setUsernameInfoIsVisible(false);
        setPasswordInfoIsVisible(true);

        clearTimeout(infoPopupTimeoutRef.current);
        infoPopupTimeoutRef.current = setTimeout(() => {
            setPasswordInfoIsVisible(false);
        }, 3000);
    };

    const handleUsernameInfoMouseEnter = () => {
        setPasswordInfoIsVisible(false);
        clearTimeout(infoPopupTimeoutRef.current);
        setUsernameInfoIsVisible(true);
    };

    const handleUsernameInfoMouseLeave = () => {
        infoPopupTimeoutRef.current = setTimeout(() => {
            setUsernameInfoIsVisible(false);
        }, 500);
    };

    const handlePasswordInfoMouseEnter = () => {
        setUsernameInfoIsVisible(false);
        clearTimeout(infoPopupTimeoutRef.current);
        setPasswordInfoIsVisible(true);
    };

    const handlePasswordInfoMouseLeave = () => {
        infoPopupTimeoutRef.current = setTimeout(() => {
            setPasswordInfoIsVisible(false);
        }, 500);
    };

    const popupError = (errorMsg) => {
        setErrMsg(errorMsg);
        setErrorVisible(true);

        clearTimeout(errorPopupTimeoutRef.current);
        errorPopupTimeoutRef.current = setTimeout(() => {
            setErrorVisible(false);
        }, 3000);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Check for empty username
        if (username === '') {
            setValidUsername(false);
            popupError("Username must not be empty.");
            userRef.current.focus();
            return;
        } else
            setValidUsername(true);

        // Check username validity
        var username_regex = /^[a-zA-Z][a-zA-Z0-9_-]{3,23}$/;
        if (!username_regex.test(username)) {
            setValidUsername(false);
            popupUsernameInfo();
            popupError("Username does not meet the requirements.");
            userRef.current.focus();
            return;
        } else
            setValidUsername(true);
        
        // Check for empty password
        if (password === '') {
            setValidPassword(false);
            popupError('Password must not be empty.');
            passwordRef.current.focus();
            return;
        } else
            setValidPassword(true);

        // Check password validity
        var password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
        if (!password_regex.test(password)) {
            setValidPassword(false);
            popupPasswordInfo();
            popupError("Given password does not meet the requirements.");
            passwordRef.current.focus();
            return;
        } else
            setValidPassword(true);
        
        // Check if confirmation password is equal to password
        if (matchPassword === '') {
            setValidMatchPassword(false);
            popupError("Confirmation password must not be empty.");
            matchPasswordRef.current.focus();
            return;
        } else
            setValidMatchPassword(true);

        if (password !== matchPassword) {
            setValidMatchPassword(false);
            popupError("Confirmation password does not match given password.");
            matchPasswordRef.current.focus();
            return;
        } else
            setValidMatchPassword(true);

        let response;
        try {
            response = await api.post('/api/auth/signup', { username, password });
            localStorage.setItem('username', username);
            localStorage.setItem('jwtToken', response.data.access_token);
            setSuccess(true);
        } catch (err) {
            if (!err?.response)
                popupError('No server response.');
            else if (err.response?.status === 500)
                popupError('An unexpected error occured.');
            else if (err.response?.status === 409) {
                setValidUsername(false);
                userRef.current.focus();
                popupError('Given username is not available! Enter another one.');
            } else
                setErrMsg('Registration Failed');
        }
    };

    return (
        <div className='App'>
            <div className="auth-page">
                {success ? (
                    <section className="success-signup-container">
                        <div className="logo-container">
                            <img src={logoImage} className="logo" alt="Logo" />
                        </div>
                        <h1>Account created successfully!</h1>
                        <button className="submit-btn"><Link to="/signin" className="navigation-link">Sign In</Link></button>
                    </section>
                ) : (
                    <section>
                        <form className="form-container" onSubmit={handleSubmit}>
                            <div className="form-content">
                                <div className="logo-container">
                                    <img src={logoImage} className="logo" alt="Logo" />
                                </div>
                                <h1 className="form-header">Sign Up</h1>

                                <div className="form-group">
                                    <label htmlFor="username" className="form-label">
                                        <div className="form-label-title-container">
                                            <span className="form-label-title">Username</span>
                                            <img
                                                className={`validity-icon ${validUsername ? '' : 'visible'}`}
                                                src={validUsername ? tickIcon : errorIcon}
                                                alt="errorIcon"
                                            />
                                        </div>
                                        <span
                                            className="info-icon"
                                            onMouseEnter={handleUsernameInfoMouseEnter}
                                            onMouseLeave={handleUsernameInfoMouseLeave}
                                            ref={usernameInfoRef}
                                        >
                                            <img className="info-icon" src={infoIcon} alt="InfoIcon" />
                                        </span>
                                        <div
                                            className={`info-popup ${usernameInfoIsVisible ? 'visible' : ''}`}
                                            style={{ top: usernameInfoPosition.top, left: usernameInfoPosition.left }}
                                        >
                                            <div className="info-popup-text">
                                                Username must:
                                                <ul>
                                                    <li>Begin with a letter</li>
                                                    <li>Contain 4 to 24 characters</li>
                                                    <li>Contain Letters, Numbers, Underscores, or Hyphens</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        id="username"
                                        className={`form-input ${validUsername ? "" : 'invalid'}`}
                                        placeholder="Enter your username"
                                        ref={userRef}
                                        onChange={(e) => setUsername(e.target.value)}
                                        value={username}
                                        aria-describedby="uidnote"
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password" className="form-label">
                                        <div className="form-label-title-container">
                                            <span className="form-label-title">Password</span>
                                            <img
                                                src={validPassword ? tickIcon : errorIcon}
                                                className={`validity-icon ${validPassword ? '' : 'visible'}`}
                                                alt="errorIcon"
                                            />
                                        </div>
                                        <span
                                            className="info-icon"
                                            onMouseEnter={handlePasswordInfoMouseEnter}
                                            onMouseLeave={handlePasswordInfoMouseLeave}
                                            ref={passwordInfoRef}
                                        >
                                            <img className="info-icon" src={infoIcon} alt="InfoIcon" />
                                        </span>
                                        <div
                                            className={`info-popup ${passwordInfoIsVisible ? 'visible' : ''}`}
                                            style={{ top: passwordInfoPosition.top, left: passwordInfoPosition.left }}
                                        >
                                            <div className="info-popup-text">
                                                Password must:
                                                <ul>
                                                    <li>Contain 8 to 24 characters</li>
                                                    <li>Include Uppercase and Lowercase letters, a number and a special character</li>
                                                    <li>Allowed special characters: - ! @ # $ %</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </label>
                                    <div className="visibility-input-container">
                                        <input
                                            type={`${passwordVisible ? 'text' : 'password'}`}
                                            id="password"
                                            className={`form-input ${validPassword ? "" : 'invalid'}`}
                                            placeholder="Enter your password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            ref={passwordRef}
                                            value={password}
                                            aria-describedby="passwordnote"
                                            autoComplete="off"
                                        />
                                        <div className="password-toggle" onClick={togglePasswordVisibility}>
                                            <img className="visibility-icon" src={`${passwordVisible ? visibilityIcon : invisibilityIcon}`} alt="VisibilityIcon" />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="confirm-password" className="form-label">
                                        <div className="form-label-title-container">
                                            <span className="form-label-title">Confirm Password</span>
                                            <img
                                                src={validMatchPassword ? tickIcon : errorIcon}
                                                className={`validity-icon ${validMatchPassword ? '' : 'visible'}`}
                                                alt="errorIcon"
                                            />
                                        </div>
                                    </label>
                                    <div className="visibility-input-container">
                                        <input
                                            type={`${matchPasswordVisible ? 'text' : 'password'}`}
                                            id="confirmPassword"
                                            className={`form-input ${validMatchPassword ? "" : 'invalid'}`}
                                            placeholder="Confirm your password"
                                            value={matchPassword}
                                            ref={matchPasswordRef}
                                            aria-describedby="confirmnote"
                                            onChange={(e) => setMatchPassword(e.target.value)}
                                            autoComplete="off"
                                        />
                                        <div className="password-toggle" onClick={toggleMatchPasswordVisibility}>
                                            <img className="visibility-icon" src={`${matchPasswordVisible ? visibilityIcon : invisibilityIcon}`} alt="VisibilityIcon" />
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" className="submit-btn">Sign Up</button>
                                <p className="navigation-text">
                                    Already have an account? <Link to="/signin" className="navigation-link">Sign In</Link>
                                </p>

                                <p ref={errRef} className={`error-popup ${errorVisible ? 'visible' : ''}`} aria-live="assertive">{errMsg}</p>
                            </div>
                        </form>
                    </section>
                )}
            </div>
        </div>
    );
};

export default SignUpPage;
