import React, { useState, useEffect } from "react";
import api from '../../../../../axiosApi/api';
import './DatasetSelectionStage.css';
import DatasetFolderIcon from '../../../../../images/folder.png';
import naIcon from "../../../../../images/naNew.png";
import ErrorIcon from "../../../../../images/error-icon.png"

const DatasetSelectionStage = ({ selectedDataset, setSelectedDataset }) => {
  const [loadingDatasets, setLoadingDatasets] = useState(false);
  const [availableDatasets, setAvailableDatasets] = useState([]);
  const [error, setError] = useState(false)
  
  useEffect(() => {
    const fetchDatasets = async () => {
      setLoadingDatasets(true);
      try {
        const response = await api.get('/api/datasets', {
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
        });
        setError(false);
        setAvailableDatasets(response.data.datasets);
        setLoadingDatasets(false);
      } catch (error) {
        setError(true);
        console.error("Error fetching datasets:", error);
        setLoadingDatasets(false);
      }
    };

    fetchDatasets();
  }, []);

  const handleDatasetSelection = (dataset) => {
    setSelectedDataset(dataset);
  };

  return (
    <div className="dataset-selection-container">
      <h5>Select a Dataset</h5>
      <div className="datasets-section">
        <h6>Available datasets</h6>
        <div className="dataset-cards-container">
          {error ? (
            <div className="message-container">
              <img src={ErrorIcon} className="message-icon" alt="Error icon" />
              <p className="message-text-main">An unexpected error occurred.</p> 
              <p className="message-text">Please try again later.</p>
            </div>
          ) : loadingDatasets ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : availableDatasets.length > 0 ? (
            <div className="dataset-cards-grid">
              {
                availableDatasets.map((dataset) => (
                  <div 
                    key={dataset["_id"]} 
                    className={`dataset-card ${selectedDataset && dataset._id === selectedDataset._id ? 'selected' : ''}`}
                    onClick={() => handleDatasetSelection(dataset)}
                  >
                    <div className="card-icon-container">
                      <img className="card-icon" src={DatasetFolderIcon} alt="Dataset Icon" />
                    </div>
                    <div className="card-fields-group">
                      <span className="card-field card-title" title={dataset.name}>{dataset.name}</span>
                      <span className="card-field card-date" title={dataset.upload_date}>{dataset.upload_date}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          ): (
            <div className="message-container">
              <img src={naIcon} className="message-icon empty-box-icon" alt="Not Available" />
              <span className="message-text">Not available datasets.</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DatasetSelectionStage;
