import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { initializeLabelStudio } from "./LabelStudioConfig";

import api from '../../../axiosApi/api';

import "./../Dashboard.css"
import "./InstanceAutomaticAnnotationsDashboard.css"

import ErrorIcon from "../../../images/error-icon.png";

function InstanceAutomaticAnnotationsDashboard() {
    const location = useLocation();  
    const { annotationModuleId, projectId, dataChunkId, instanceId, instanceName, instanceRelativePath } = location.state;
    const [instanceLoading, setInstanceLoading] = useState(false)
    const [projectTaskType, setProjectTaskType] = useState()
    const [error, setError] = useState(false);


    useEffect(() => {
        fetchData();
        return () => {};
    }, [projectId, dataChunkId, instanceId, instanceRelativePath]);


    const fetchData = async () => {
        setInstanceLoading(true);

        try {
            // Fetch project details
            const projectDetailsRes = await api.get(`/api/projects/${projectId}`, {
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem('jwtToken')
            },
            params: {
                'include-modality-metadata': "true",
                "include-task-type-metadata": "true"
            }
            });
            setError(false);
    
            const fetchedProject = projectDetailsRes.data.project;
            const projectModality = fetchedProject['projectModality'];
            const projectTaskType = fetchedProject['projectTaskType'];
            const projectLabels = fetchedProject['projectLabels'];
            setProjectTaskType(projectTaskType['name']);

            // Fetch automatic annotations submitted by the model
            const params = { 'annotation-module-id': annotationModuleId }
            const annotationsRes = await api.get(`/api/data-chunks/${dataChunkId}/instances/${instanceId}/automatic-annotations`, {
                headers: {
                    "Authorization": 'Bearer ' + localStorage.getItem('jwtToken')
                },
                params: {
                    'annotation-module-id': annotationModuleId
                }
            });
            const fetchedAnnotations = annotationsRes.data.annotations;
            await initializeLabelStudio(projectModality, 
                                        projectTaskType, 
                                        projectLabels, 
                                        fetchedAnnotations, 
                                        instanceRelativePath,
                                        handleAnnotationUpdate);
        } catch (error) {
            setError(true);
            console.error("Error:", error);
        } finally {
            setInstanceLoading(false);
        }
    };


    const handleAnnotationUpdate = async (annotationId, annotationBody) => {
        setInstanceLoading(true);

        try {
            const res = await api.put(
                `/api/annotations/${annotationId}`, 
                {
                    annotation_body: annotationBody 
                }, 
                {
                    headers: { 
                        "Authorization": 'Bearer ' + localStorage.getItem('jwtToken'),
                        'Content-Type': 'application/json' 
                    }
                }
            );

            fetchData()
        } catch (error) {
            console.error("Error updating annotation:", error);
            setInstanceLoading(false);
        }
    };


    const configureStyle = () => {
        if (projectTaskType === "PolygonLabels"){
            import("./AutoAnnotsPolygonLabels.css");
            return("auto-annots-polygon-labels")
        } 
        else if (projectTaskType === "RectangleLabels"){
            import("./AutoAnnotsRectangleLabels.css");
            return("auto-annots-rectangle-labels")
        } 
        else if (projectTaskType === "ImageClassification"){
            import("./AutoAnnotsImageClassification.css");
            return("auto-annots-image-classification");
        } 
        else if (projectTaskType === "ImageCaptioning"){
            import("./AutoAnnotsImageCaptioning.css");
            return("auto-annots-image-captioning");
        } 
        else if (projectTaskType === "AudioCaptioning"){
            import("./AutoAnnotsAudioCaptioning.css");
            return("auto-annots-audio-captioning");
        } 
        else if (projectTaskType === "AudioClassification"){
            import("./AutoAnnotsAudioClassification.css");
            return("auto-annots-audio-classification");
        } 
        else if (projectTaskType === "SegmentsAudioClassification"){
            import("./AutoAnnotsSegmentsAudioClassification.css");
            return("auto-annots-segments-audio-classifiction");
        } 
        else if (projectTaskType === "SegmentsAudioCaptioning"){
            import("./AutoAnnotsSegmentsAudioCaptioning.css");
            return("auto-annots-segments-audio-captioning");
        } 
    }


    return (
        <>
            <section className="dashboard instance-automatic-annotations-dashboard">
                <h2>{instanceName}</h2>
                <div className="label-studio-container">
                {error ? (
                    <div className="message-container">
                        <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                        <p className="message-text-main">An unexpected error occurred.</p> 
                        <p className="message-text">Please try again later.</p>
                    </div>
                ) : instanceLoading && (
                    <div className="loading-indicator">
                    <div className="spinner"></div>
                    <span>Loading...</span>
                    </div>
                ) 
                }
                <div 
                id="label-studio" 
                className={configureStyle()}
                style={{ display: instanceLoading ? 'none' : 'block' }}
                />
                </div>
            </section>
        </>
    )
}

export default InstanceAutomaticAnnotationsDashboard