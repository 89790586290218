import React, { useState, useEffect } from "react";
import JSZip from 'jszip';
import api from '../../../axiosApi/api';

import xIcon from "../../../images/x-icon.png";
import uploadIcon from "../../../images/upload-compressed-icon.png";
import folderIcon from "../../../images/folder.png";
import fileIcon from "../../../images/file.png";
import zipIcon from "../../../images/zip.png"
import uploadingGif from "../../../images/dockerizing.gif"
import dockerizingGif from "../../../images/dockerizing.gif"
import errorIcon from "../../../images/error-icon.png"

import "../Modals.css";
import "./UploadModelModal.css";
import BackgroundOverlay from '../../BackgroundOverlay/BackgroundOverlay';


const UploadModelModal = ({ open, onClose, uploadModel }) => {
    const [availableModalities, setAvailableModalities] = useState([]);
    const [availableTaskTypes, setAvailableTaskTypes] = useState([]);
    const [selectedModalityIndex, setSelectedModalityIndex] = useState(0);
    const [selectedTaskTypeIndex, setSelectedTaskTypeIndex] = useState(0);
    const [dropdownTaskTypes, setDropdownTaskTypes] = useState([]);
    const [compressedContentMeta, setCompressedContentMeta] = useState([]);
    const [selectedZipName, setSelectedZipName] = useState();
    const [zipContent, setZipContent] = useState();
    const [modelName, setModelName] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [dockerizing, setDockerizing] = useState(false);
    const [error, setError] = useState(false);

    const [requiresLabelSelection, setRequiresLabeSelection] = useState(false); 
    const [modelLabels, setModelLabels] = useState([]);
    const [labelName, setLabelName] = useState();
    const [folderContentIsValid, setFolderContentIsValid] = useState(false);

    const fileFormatIcons = {
        "wav": "wav.png",
        "mp3": "mp3.png",
        "png": "png.png",
        "jpg": "jpg.png",
        "Dockerfile": "dockerfile.png",
        "txt": "txt.png",
        "py": "python.png",
        "zip": "zip.png",
        "pdf": "pdf.png",
        "json": "json.png",
        "psd": "psd.png",
        "misc": "misc.png"
    }

    useEffect(() => {
        api.get('/api/modalities', {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
        }).then((res) => {
            setAvailableModalities(res.data.modalities);
        }).catch((error) => {
            console.log(error);
        });
    
        // Fetch available task types
        api.get('/api/task-types', {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
        }).then((res) => {
            setAvailableTaskTypes(res.data.task_types);
        }).catch((error) => {
            console.log(error);
        });
    }, []);


    // Filter available templates based on selected modality
    useEffect(() => {  
        if (availableModalities.length > 0 && availableTaskTypes.length > 0 && selectedModalityIndex!=undefined) {
            setDropdownTaskTypes(availableTaskTypes.filter(taskType => taskType["modality"] === availableModalities[selectedModalityIndex].name));
            const filteredTaskTypes = availableTaskTypes.filter(
                taskType => taskType["modality"] === availableModalities[selectedModalityIndex].name
            )
            const taskTypeIndex = availableTaskTypes.findIndex(taskType => taskType.name === filteredTaskTypes[0].name)
            setSelectedTaskTypeIndex(taskTypeIndex)
            setModelLabels([])
        }
    }, [selectedModalityIndex, availableModalities, availableTaskTypes]);


    useEffect(() => {   
        if(availableTaskTypes.length > 0 && selectedTaskTypeIndex!=undefined){
            if (['PolygonLabels', 'RectangleLabels', 'ImageClassification', 'AudioClassification', 'SegmentsAudioClassification'].includes(availableTaskTypes[selectedTaskTypeIndex].name)) {
                setRequiresLabeSelection(true);
                return;
            } 
            setRequiresLabeSelection(false);
        }
        
    }, [selectedTaskTypeIndex]);


    useEffect(() => {
        setSelectedModalityIndex(0);
        if (availableModalities.length > 0 && availableTaskTypes.length > 0 && selectedModalityIndex!=undefined) {
            setDropdownTaskTypes(availableTaskTypes.filter(taskType => taskType["modality"] === availableModalities[selectedModalityIndex].name));
            const filteredTaskTypes = availableTaskTypes.filter(
                taskType => taskType["modality"] === availableModalities[selectedModalityIndex].name
            )
            const taskTypeIndex = availableTaskTypes.findIndex(taskType => taskType.name === filteredTaskTypes[0].name)
            setSelectedTaskTypeIndex(taskTypeIndex)
            setModelLabels([])
        }
        setModelLabels([]);

        setModelName(selectedZipName); 

    }, [selectedZipName]);


    useEffect(() => { 
        if(compressedContentMeta && compressedContentMeta.length>0){
            const folderContentStatus = checkIfContentIsCorrect();
            setFolderContentIsValid(folderContentStatus);
        }
    }, [compressedContentMeta]);


    const checkIfContentIsCorrect = () => {
        const hasDockerfile = compressedContentMeta.some(item => item.name === 'Dockerfile');
        const hasInferenceFile = compressedContentMeta.some(item => item.name === 'inference' && item.format === 'py');
        const hasEnvironmentFile = compressedContentMeta.some(item => item.name === 'environment' && item.format === 'yml');
        
        return hasDockerfile && hasInferenceFile && hasEnvironmentFile;
    }


    const configureFileIcon = async (format) => {
        let iconName;
        if (format in fileFormatIcons) {
            iconName = fileFormatIcons[format];
        } else {
            iconName = fileFormatIcons['misc'];
        }
    
        try {
            const icon = await import(`../../../images/file-formats/${iconName}`);
            return icon; 
        } catch (error) {
            console.error("Error importing icon:", error);
        }
    };


    const handleModalityChange = (event) => {
        const selectedName = event.target.value;
        const selectedIndex = availableModalities.findIndex(modality => modality.name === selectedName);
        setSelectedModalityIndex(selectedIndex);  
    };


    const handleTaskTypeChange = (event) => {
        const selectedName = event.target.value;
        const selectedIndex = availableTaskTypes.findIndex(taskType => taskType.name === selectedName);
        setSelectedTaskTypeIndex(selectedIndex);
    };


    const handleFileSelection = async (event) => {
        try {
            const file = event.target.files[0]; // Get the single selected file
            if (!file || !file.name.endsWith('.txt')) {
                console.error('No valid .txt file selected');
                return;
            }
        
            const reader = new FileReader();
        
            // When the file is successfully read
            reader.onload = (e) => {
                const fileContent = e.target.result;
                const lines = fileContent.split('\n')
                                            .map(line => line.trim())
                                            .filter(line => line !== '');
                
                setModelLabels(lines);
            };
        
            reader.readAsText(file);
        } catch (error) {
            console.error('Error selecting or reading file:', error);
        }
    };


    const handleFolderSelection = async (event) => {
        setIsLoading(true);
    
        try {
            const files = event.target.files;
            if (!files.length) {
                console.error('No files selected');
                setIsLoading(false);
                return;
            }
            
            // Extract the folder name from the first file's webkitRelativePath
            const firstFile = files[0];
            const firstFilePath = firstFile.webkitRelativePath;
            const selectedFolderName = firstFilePath.split('/')[0];
            setSelectedZipName(selectedFolderName);
    
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const relativePath = file.webkitRelativePath || file.name;
    
                const slashSplitTokens = relativePath.split('/');
                const parentPath = slashSplitTokens.length === 2 ? 'root' : slashSplitTokens.slice(0, -1).join("/");
    
                let fileName;
                const dotSplitTokens = file.name.split('.');
                if (file.name.includes("."))
                    fileName = dotSplitTokens.length === 2 ? dotSplitTokens[0] : dotSplitTokens.slice(0, -1).join(".");
                else
                    fileName = file.name;
    
                let fileType;
                if (fileName === "Dockerfile")
                    fileType = "Dockerfile";
                else
                    fileType = dotSplitTokens.length === 2 ? dotSplitTokens[1] : dotSplitTokens[dotSplitTokens.length - 1];
    
                const fileSize = file.size;
    
                // Asynchronously get the file icon
                const icon = await configureFileIcon(fileType);
    
                const instance = {
                    "type": "file",
                    "name": fileName,
                    "format": fileType,
                    "size": fileSize,
                    "parentPath": parentPath,
                    "icon": icon.default // Get the default export of the module (image path)
                };
    
                // Update state with the new instance
                setCompressedContentMeta(prevItems => [...prevItems, instance]);
            }
    
            setIsLoading(false);
        } catch (error) {
            console.error('Error selecting folder:', error);
            setIsLoading(false);
        }
    };
    

    const handleUpload = async () => {
        setDockerizing(true)

        const modelContent = zipContent
        const name = modelName
        const modelModalityId = availableModalities[selectedModalityIndex]._id
        const modelTaskTypeId = availableTaskTypes[selectedTaskTypeIndex]._id

        const uploadSuccess = await uploadModel(modelContent, name, modelModalityId, modelTaskTypeId, modelLabels);

        if(!uploadSuccess){
            clearState();
            setError(true);
        }
        else{
            clearState();
            onClose();
        }
    };

    const handleLabelNameChange = (event) => {
        setLabelName(event.target.value);
    };

    const addModelLabel = () => {
        setModelLabels((prevLabels) => [...prevLabels, labelName]);
        setLabelName();
    }

    const deleteModelLabel = (labelIndex) => {
        setModelLabels((prevLabels) => prevLabels.filter((_, i) => i !== labelIndex));
    }

    const clearState = () => {
        setSelectedModalityIndex(0);
        setCompressedContentMeta([]);
        setSelectedZipName();
        setZipContent();
        setModelName();
        setModelLabels([]);
        setIsLoading(false);
        setDockerizing(false);
        setError(false);
    };

    const onCloseClick = () => {
        clearState();
        onClose();
    };

    if (!open) return null;

    return (
        <>
        <BackgroundOverlay />
        <div className="model-upload-modal modal">
            <div className="models-modal-header modal-header">
                <h3>Add a Model</h3>
                <img src={xIcon} className="x-icon" onClick={onCloseClick} alt="xIcon" />
            </div>
            <div className="models-modal-body modal-body">
            {isLoading ? (
                <div className="loading-indicator">
                    <div className="spinner"></div>
                    <span>Loading...</span>
                </div>
            ) : dockerizing ? (
                <div className="dockerizing-indicator">
                    <img src={dockerizingGif}/>
                    <div>Model is being dockerized. Please wait ...</div>
                </div>
            ) : error ? (
                <div className="message-container">
                    <img src={errorIcon} className="message-icon" alt="Error icon" />
                    <p className="message-text-main">An unexpected error occurred.</p> 
                    <p className="message-text">Please try again later.</p>
                </div>
            ) : compressedContentMeta.length === 0 ? (
                <div className="file-upload-section">
                    <div className="file-upload-container">
                        <label htmlFor="fileInput" className="file-input-label">
                            <img src={uploadIcon} alt="Upload Icon" />
                            <span>Select a folder that contains the model</span>
                        </label>
                        <input 
                            type="file" 
                            id="fileInput" 
                            style={{ display: 'none' }} 
                            webkitdirectory="" 
                            mozdirectory="" 
                            onChange={handleFolderSelection} 
                        />
                    </div>
                </div>
            ) : (
                <div className="upload-finalization-section">
                    <div className="finalization-header">
                        <div>
                            <h5>Compressed content</h5> 
                        </div>
                        <button className="clear-selected-btn" onClick={clearState}>
                            Clear
                        </button>
                    </div>
                    <div className="finalization-body">
                        <div className="zip-info-container">
                            <div className="zip-img">
                                <img src={folderIcon} alt="zipIcon"/>
                            </div>
                            <div className="zip-info">
                                <div className="zip-name">{selectedZipName}</div>
                                <div className="zip-files-num"> ({compressedContentMeta.length} items)</div>
                            </div>
                        </div>
                        <div className="list-container">
                            <div className="columns-header">
                                <div className="header-field"></div>
                                <div className="header-field">Name</div>
                                <div className="header-field">Size</div>
                                <div className="header-field">Format</div>
                                <div className="header-field">Parent Path</div>
                            </div>
                            <div className="compressed-items-list">
                                {compressedContentMeta.map((item, index) => (
                                    <div key={index} className="list-item">
                                        <div className="item-field">
                                            <img src={item.icon} title={item.format}/>
                                        </div>
                                        <div className="item-field">
                                            <div className="field-text" title={item.name}>{item.name}</div>
                                        </div>
                                        <div className="item-field">
                                            <div className="field-text" title={item.size}>{item.size}</div>
                                        </div>
                                        <div className="item-field">
                                            <div className="field-text" title={item.format}>{item.format}</div>
                                        </div>
                                        <div className="item-field">
                                            <div className="field-text" title={item.parentPath}>{item.parentPath}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='model-info-container'>
                            <h5>Model Details</h5> 
                            <div className="model-info-fields"> 
                                <div className="info-row">
                                    <div className="info-div">
                                        <h6>Model name</h6>
                                        <input
                                            type="text"
                                            id="folder-name-input"
                                            placeholder={modelName}
                                            onChange={(e) => {setModelName(e.target.value); }}
                                            value={modelName}
                                            aria-describedby="uidnote"
                                            autoComplete="False"
                                        />
                                    </div>
                                    <div className="info-div">
                                        <h6>Modality</h6>
                                        <div className="dropdown">
                                            <select
                                                id="model-modality"
                                                value={selectedModalityIndex ? availableModalities[selectedModalityIndex].name : ''}
                                                onChange={handleModalityChange}
                                            >
                                                <option value="Modality" disabled>Modality</option>
                                                {availableModalities.map((modality, index) => (
                                                    <option key={index} value={modality.name}>
                                                        {modality['name']}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="info-div">
                                        <h6>Task Type</h6>
                                        <div className="dropdown">
                                            <select
                                                id="model-task-type"
                                                value={selectedTaskTypeIndex ? availableTaskTypes[selectedTaskTypeIndex].name : ''}
                                                onChange={handleTaskTypeChange}
                                            >
                                                <option value="Task Type" disabled>Task Type</option>
                                                {dropdownTaskTypes.map((task, index) => (
                                                    <option key={index} value={task.name}>
                                                        {task.description}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                
                                {(requiresLabelSelection) && (
                                    <div className="info-row">
                                        <div className="info-div labels-div">
                                            <h6>Labels definition</h6>
                                            <div className="labels-container">
                                                <label>
                                                    Add label:
                                                    <input
                                                        type="text"
                                                        onChange={handleLabelNameChange}
                                                        value={labelName}
                                                    />
                                                </label>
                                                <button
                                                    onClick={addModelLabel}
                                                    disabled={labelName==='' || labelName===undefined || modelLabels.includes(labelName)}
                                                    className="add-label-btn"
                                                >+</button>

                                                <span>or</span>

                                                <label htmlFor="fileInput" className="import-file-txt">
                                                    <span> Import from .txt file</span>
                                                </label>

                                                <input 
                                                    type="file" 
                                                    id="fileInput" 
                                                    style={{ display: 'none' }} 
                                                    accept=".txt"
                                                    onChange={handleFileSelection} 
                                                />

                                                <div className="labels-list">
                                                    {modelLabels.map((labelName, index) => (
                                                        <div className="label-item-container">
                                                            <div className="label-item">{labelName}</div>
                                                            <div className="label-item-delete" onClick={() => deleteModelLabel(index)}>x</div>
                                                        </div>    
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            </div>
            <div className="models-modal-footer modal-footer">
                <button
                    className="submission-footer-btn"
                    disabled={
                        compressedContentMeta.length === 0 || 
                        isLoading ||
                        (requiresLabelSelection && modelLabels.length===0) ||
                        !folderContentIsValid
                    }
                    onClick={() => handleUpload()}
                >
                    Add
                </button>
            </div>
        </div>
        </>
    );
};

export default UploadModelModal;
