import React, { useState, useEffect } from "react";
import filterIcon from "../../../images/filter-dark.png";
import uploadIcon from "../../../images/upload-icon.png";
import "../UtilsBar.css";


function AnnotatorsUtilsBar({ allAnnotators, filteredAnnotators, selectedType, setSelectedType, setFilteredAnnotators, openAnnotatorCreationModal }) {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleTypeChange = (type) => {
        setSelectedType(type);
        setDropdownOpen(false);
        
        if(type === "All"){
            setFilteredAnnotators(allAnnotators)
        } else {
            const filteredAnnotators = allAnnotators.filter(annotator => annotator.type === type);
            setFilteredAnnotators(filteredAnnotators);
        }
    }


    return (
        <div className="utils-container">
            <div className='filters-section' >
                {/* <div className="filters-header">
                    <img src={filterIcon}/>
                    <div>Filters</div>
                </div> */}
                <div className="filters">
                    <div className="dropdown">
                        <div className="dropdown-toggle" onClick={toggleDropdown}>
                            {selectedType || 'Select an option'}
                        </div>
                        {isDropdownOpen && (
                            <div className="dropdown-menu">
                            <div className="dropdown-item" onClick={() => handleTypeChange('All')}>All annotators</div>
                            <div className="dropdown-item" onClick={() => handleTypeChange('HumanAnnotator')}>Human annotators</div>
                            <div className="dropdown-item" onClick={() => handleTypeChange('MachineAnnotator')}>Machine annotators</div>
                            <div className="dropdown-item" onClick={() => handleTypeChange('ExternalAnnotator')}>External annotators</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {
                selectedType === 'MachineAnnotator' && (
                    <button className="create-annotator-btn" title="Create machine annotator" onClick={() => openAnnotatorCreationModal()}>
                        <p className="upload-btn-text">Create Annotator</p>
                        <img src={uploadIcon} className="upload-btn-icon" alt="UploadIcon" />
                    </button>
                )
            }
            
        </div>
    );
}

export default AnnotatorsUtilsBar;
