import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import api from '../../../axiosApi/api';

import "./../Dashboard.css";
import "./ModelsDashboard.css";
import ModelsUtilsBar from '../../UtilsBar/ModelsUtilsBar/ModelsUtilsBar';
import UploadModelModal from '../../Modals/UploadModelModal/UploadModelModal'

import naIcon from "../../../images/naNew.png";
import optionIcon from "../../../images/menu-vertical.png";
import deleteIcon from "../../../images/delete.png";
import ErrorIcon from "../../../images/error-icon.png"
import ModelIcon from "../../../images/model.png"


function ModelsDashboard() {
    const location = useLocation();
    const currentPath = location.pathname;

    const [models, setModels] = useState([])
    const [openModelUploadModal, setOpenModelUploadModal] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showOptions, setShowOptions] = useState({});


    useEffect(() => {
        fetchModels();
        return () => {};
    }, []);


    useEffect(()=>{
        setShowOptions({})
    }, [models, openModelUploadModal])


    const fetchModels = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/models', {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')}
            });
            setModels(response.data.models);
            setError(false);
        } catch (error) {
            setError(true);
            console.error("Error fetching models:", error);
        } finally {
            setLoading(false);
        }
    };

    const toggleOptions = (index) => {
        setShowOptions((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    async function uploadModel(modelContent, modelName, modalityId, taskTypeId, modelLabels) {
        try {
            const formData = new FormData();
            formData.append('file', modelContent);
            formData.append('modelName', modelName);
            formData.append('modalityId', modalityId);
            formData.append('taskTypeId', taskTypeId);
            formData.append('modelLabels', JSON.stringify(modelLabels));

            const response = await api.post('/api/models', formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 1800000
            });
    
            if (response.status !== 200 && response.status !== 201) {
                console.error('Unexpected response:', response);
                return false;
            }
            fetchModels()
            return true;
        } catch (error) {
            if (error.response) {
                console.error('Server responded with an error:', error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up the request:', error.message);
            }
            return false;
        }
    }

    const deleteModel = async (modelId) => {
        try {
            await api.delete(`/api/models/${modelId}`, {
                headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
            });
            fetchModels();
        } catch (error) {
            console.error("Error deleting model:", error);
        }
    };

    return (
        <>
            <UploadModelModal 
                open={openModelUploadModal} 
                onClose={() => setOpenModelUploadModal(false)} 
                uploadModel= {uploadModel}
            />
            <section className="dashboard models-dashboard">
                <h2>Dashboards / <b>Models Dashboard</b> </h2>
                <ModelsUtilsBar 
                    openModelUploadModal={() => setOpenModelUploadModal(true)}
                />
                <div className="models-container">
                    {error ? (
                        <div className="message-container">
                            <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                            <p className="message-text-main">An unexpected error occurred.</p> 
                            <p className="message-text">Please try again later.</p>
                        </div>
                    ) : loading ? (
                        <div className="loading-indicator">
                            <div className="spinner"></div>
                            <span>Loading...</span>
                        </div>
                    ) : models.length > 0 ? (
                        <div className="model-cards-grid">
                            {models.map((model, index) => (
                                <div key={index} className="model-card">
                                    <div className="card-options-container">
                                        <div className="card-options-icon" onClick={() => toggleOptions(index)}>
                                            <img src={optionIcon} alt="Options" />
                                        </div>
                                        {showOptions[index] && (
                                            <ul className="card-options-list">
                                                <li className="list-item" onClick={() => deleteModel(model._id)}>
                                                <img src={deleteIcon} className="list-item-icon" alt="Delete" />
                                                <span className="list-item-text">Delete Model</span>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                    <div className="card-body">
                                        <div className="card-icon-container">
                                            <img className="card-icon" src={ModelIcon} alt="ModelIcon" />
                                        </div>
                                        <div className="card-fields-group">
                                            <div className="card-field card-title" title={model.name}>{model.name}</div>
                                            <div className="modality-tasktype-group">
                                                <div className="card-field" title={model.modality.name}>{model.modality.name}</div>
                                                <span> - </span>
                                                <div className="card-field" title={model.task_type.name}>{model.task_type.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                    ) : (
                        <div className="message-container">
                            <img src={naIcon} className="message-icon empty-box-icon" alt="NA Icon"/>
                            <span className="message-text">No available models.</span>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}

export default ModelsDashboard;
