import React, { useState, useEffect } from "react";
import "../UtilsBar.css";
import ViewHelpIcon from "../../../images/view-help-outlined.png"

function AnnotationUtilsBar({loadAssistance, instanceLoading}) {

    return (
        <div className="utils-container annotation-utils-bar">
            <p>View assistance: </p>
            <button 
                className="util-btn" 
                title="View assistance" 
                onClick={loadAssistance}
                disabled = {instanceLoading}
            >
                <img src={ViewHelpIcon} className="btn-icon" alt="ViewHelpIcon" />
            </button>
        </div>
    );
}

export default AnnotationUtilsBar;
