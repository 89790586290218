import LabelStudio from 'label-studio';
import api from '../../../axiosApi/api';


let annotationStartTime;

async function configureView(projectModality, taskTypeName, taskTypeView, taskTypeAnnotationType, 
                              projectLabels, dataChunkId, instanceRelativePath){
  let labelsConfig = "";
  let configuredView = "";

  // Integrate given labels or choices
  if (taskTypeAnnotationType === "labels") {
    labelsConfig = projectLabels.map((label) => (
      `<Label value="${label.name}" background="${label.color}" />`
    )).join(''); 
    configuredView = taskTypeView.replace(/\$labels/g, labelsConfig);
  } 
  else if (taskTypeAnnotationType === "choices") {
    labelsConfig = projectLabels.map((label) => (
      `<Choice value="${label.name}" />`
    )).join(''); 
    configuredView = taskTypeView.replace(/\$choices/g, labelsConfig);
  } 
  else if (['text', 'rating'].includes(taskTypeAnnotationType)) {
    configuredView = taskTypeView
  }

  // Handle AdversarialDetection labeling setup
  if (taskTypeName === "AdversarialDetection") {
    // Extract the parent folder path and file name
    const lastSlashIndex = instanceRelativePath.lastIndexOf('/');
    const parentFolderPath = instanceRelativePath.substring(0, lastSlashIndex + 1);
    const fileNameWithExtension = instanceRelativePath.substring(lastSlashIndex + 1);
    const fileName = fileNameWithExtension.split(".")[0];
    const fileExtension = fileNameWithExtension.split(".").slice(-1)[0];

    let fileURL1 = null;
    let fileURL2 = null;
    let audioPairName = null;

    // Check if file has perturbated copy or not
    const isPaired = await checkIfInstanceIsPaired(fileName, dataChunkId)
    if(isPaired){
      // Create the new filename and filepath with "_perturbated" appended
      const perturbatedFileName = `${fileName}_perturbated.${fileExtension}`;
      const perturbatedFilePath = parentFolderPath + perturbatedFileName;

      // Make dynamic imports
      fileURL1 = await fetchFile(instanceRelativePath);
      fileURL2 = await fetchFile(perturbatedFilePath);
      
      audioPairName = fileName + '-' + perturbatedFileName;
    } else{
      fileURL1 = await fetchFile(instanceRelativePath);
      fileURL2 = fileURL1

      audioPairName = fileName + '-' + fileName;
    }
    
    // Configure Adversarial view
    configuredView = configuredView.replace(/\$audio1/g, fileURL1);
    configuredView = configuredView.replace(/\$audio2/g, fileURL2);
    configuredView = configuredView.replace(/\$audioPairName/g, audioPairName);
  }
  else if(taskTypeName === "SpeechEmotionRecognition") {
    configuredView = configuredView.replace(/\$instancePathArousal/g, instanceRelativePath+"(Arousal)");
    configuredView = configuredView.replace(/\$instancePathValence/g, instanceRelativePath+"(Valence)");
    configuredView = configuredView.replace(/\$instancePathEmotion/g, instanceRelativePath+"(Emotion)");
    
    const fileURL = await fetchFile(instanceRelativePath);
    configuredView = configuredView.replace(/\$srcFile/g, fileURL);
  }
  else if(taskTypeName === "MusicEmotionRecognition5Point" || taskTypeName === "MusicEmotionRecognition3Point") {
    configuredView = configuredView.replace(/\$instancePathArousal/g, instanceRelativePath+"(Arousal)");
    configuredView = configuredView.replace(/\$instancePathValence/g, instanceRelativePath+"(Valence)");
    
    const fileURL = await fetchFile(instanceRelativePath);
    configuredView = configuredView.replace(/\$srcFile/g, fileURL);
  }
  else{
    const fileURL = await fetchFile(instanceRelativePath);
    configuredView = configuredView.replace(/\$srcFile/g, fileURL);
  }
  
  return configuredView
}


async function fetchFile(instanceRelativePath) {
  try {
    // Log the instanceRelativePath for debugging purposes
    console.log("Fetching file with path:", instanceRelativePath);

    // Make the request to get the file as a blob
    const response = await api.get(`/api/file`, {
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem('jwtToken')
      },
      params: {
        'relative_path': encodeURIComponent(instanceRelativePath)  
      },
      responseType: 'blob'  // Specify that the response is binary (blob)
    });

    // Check if the response is okay (status 200)
    if (response.status === 200) {
      console.log("File fetched successfully!");

      // Convert the blob to a URL
      const fileURL = URL.createObjectURL(response.data);  
      return fileURL;  
    } else {
      console.error("Failed to fetch the file, status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error fetching from backend:", error);
    return null;
  }
}


async function checkIfInstanceIsPaired(fileName, dataChunkId) {
  // Create the new filename and filepath with "_perturbated" appended
  const perturbatedFileName = `${fileName}_perturbated`;

  try {
    const res = await api.get(`/api/dataChunks/${dataChunkId}/instances`, {
      headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') },
      params: {
        name: perturbatedFileName,
      }
    });

    const instances = res.data.instances 
    if (instances && instances.length > 0) {
      return true;
    }

    return false;

  } catch (error) {
    console.error("Error fetching instances:", error);
    return false;
  }
}

export const initializeLabelStudio = async (projectModality, projectTaskType, projectLabels, assistanceAnnotations, 
                                            dataChunkId, instanceId, instanceRelativePath, handleAnnotationSubmission) => {

    var taskTypeName = projectTaskType["name"];
    var taskTypeView = projectTaskType["view"];
    var taskTypeAnnotationType = projectTaskType["annotation_type"];

    const viewConfig = await configureView(projectModality["name"], taskTypeName, taskTypeView, taskTypeAnnotationType, 
                                            projectLabels, dataChunkId, instanceRelativePath);

    const interfaceConfig = [
      "controls",
      "side-column",
      "annotations:menu",
    ];

    const labelStudioConfig = {
      config: viewConfig,
      interfaces: interfaceConfig,
      user:{
        pk: 1,
        firstName: "",
        lastName: "",
      },
      task: {
        annotations: assistanceAnnotations,
      },
      onLabelStudioLoad: function (LS) {
        if(assistanceAnnotations.length === 0){
          annotationStartTime = new Date();
          var c = LS.annotationStore.addAnnotation({
            userGenerate: true,
            createdBy: "User",
            createdDate: new Date()
          });
          
          LS.annotationStore.selectAnnotation(c.id);
        }
      },
      onSubmitAnnotation: async function (LS, annotation) {  
        let annotationEndTime = new Date();
        let elapsedTime = (annotationEndTime - annotationStartTime) / 1000;
        let annotationBody = annotation.serializeAnnotation()

        handleAnnotationSubmission(annotationBody, elapsedTime, instanceId, dataChunkId) 
      }
    }
  
  const labelStudio = new LabelStudio("label-studio", labelStudioConfig);
}


