import LabelStudio from 'label-studio';


async function configureView(projectModality, taskTypeView, taskTypeAnnotationType, projectLabels, instanceRelativePath){
  let labelsConfig = "";
  let configuredView = "";

  // Integrate given labels
  if (taskTypeAnnotationType === "labels") {
    labelsConfig = projectLabels.map((label) => (
      `<Label value="${label.name}" background="${label.color}" />`
    )).join(''); 
    configuredView = taskTypeView.replace(/\$labels/g, labelsConfig);
  } 
  // Integrate given choices
  else if (taskTypeAnnotationType === "choices") {
    labelsConfig = projectLabels.map((label) => (
      `<Choice value="${label.name}" />`
    )).join(''); 
    configuredView = taskTypeView.replace(/\$choices/g, labelsConfig);
  } 
  // Handle rest annotation types
  else if (['text', 'rating'].includes(taskTypeAnnotationType)) {
    configuredView = taskTypeView
  }

  const instanceFilePath = await fetchFile(`/datasets${instanceRelativePath}`, projectModality);
  configuredView = configuredView.replace(/\$srcFile/g, instanceFilePath);
  
  return configuredView;
}

async function fetchFile(filePath, projectModality) {
  if(projectModality==="Audio"){
    try {
      const response = await fetch(filePath);
      const arrayBuffer = await response.arrayBuffer();
      return URL.createObjectURL(new Blob([arrayBuffer]));
    } catch (error) {
      console.error("Error fetching audio:", error);
      return null;
    }
  }
  else if(projectModality==="Image"){
    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  } 
}


export const initializeLabelStudio = async (projectModality, projectTaskType, projectLabels, 
                                              fetchedAnnotations, instanceRelativePath, handleAnnotationUpdate) => {
  const taskTypeView = projectTaskType["view"];
  const taskTypeAnnotationType = projectTaskType["annotation_type"];

  const viewConfig = await configureView(projectModality["name"], taskTypeView, taskTypeAnnotationType, projectLabels, instanceRelativePath);
  const interfaceConfig = [
    "controls",
    "side-column",
    "annotations:menu"
  ];

  const labelStudioConfig = {
    config: viewConfig,
    interfaces: interfaceConfig,
    user:{
      pk: 1,
      firstName: "",
      lastName: "",
    },
    task: {
      annotations: fetchedAnnotations.map((annotation, index) => {
        annotation['userGenerate'] = true;
        return annotation;
      })
    },
    onLabelStudioLoad: function (LS) {
      if(fetchedAnnotations.length === 0){
        var c = LS.annotationStore.addAnnotation({
            userGenerate: true,
            createdBy: "System",
            id: "Empty Annotation",
            createdDate: new Date()
        });
        LS.annotationStore.selectAnnotation(c.id);
      }
    }, onSubmitAnnotation: async function (LS, annotation) {  
      const annotationBody = annotation.serializeAnnotation()
      const annotationId = annotation.pk

      handleAnnotationUpdate(annotationId, annotationBody) 
    }
  };

  const labelStudio = new LabelStudio("label-studio", labelStudioConfig);
};