import React, { useState, useEffect } from "react";
import filterIcon from "../../../images/filter-dark.png";
import sortIcon from "../../../images/sort-dark.png";
import sortDownIcon from "../../../images/sort-down-dark.png";
import AnnotatorsIcon from "../../../images/annotators.png";
import ImportAnnotationsIcon from "../../../images/import-annotations.png"
import DownloadIcon from "../../../images/download-icon.png";

import "../UtilsBar.css";
import "./ProjectInstancesUtilsBar.css";

function AdminProjectInstancesUtilsBar({ selectedFilteringOption, setSelectedFilteringOption, projectStatus, setOpenFiltersModal, setOpenAssignmentsModal, setOpenProjectStatusModal, setOpenImportAnnotationsModal, exportProjectAnnotations}) {
  const [selectedSortOption, setSelectedSortOption] = useState('Sort By');
  const [isActive, setIsActive] = useState(projectStatus==="Active");

  const handleSortOptionChange = (option) => {
    setSelectedSortOption(option);
  };

  useEffect(() => {
    setIsActive(projectStatus === "Active");
  }, [projectStatus]);


  return (
      <div className="utils-container">
        <div className="filtering-utils-section">
          <button 
            className={`filter-btn ${selectedFilteringOption === "All instances" ? "selected" : ""}`}
            onClick={() => setSelectedFilteringOption("All instances")}
          >
            All instances
          </button>
          <button 
            className={`filter-btn ${selectedFilteringOption === "Automatic annotations" ? "selected" : ""}`}
            onClick={() => setSelectedFilteringOption("Automatic annotations")}
          >
            Automatic annotations
          </button>
        </div>
        <div className="main-utils-section">
          <div className="project-status-switch-container">
            <span className="label">Status:</span>
            {
              (projectStatus==="Complete") ? (
                <div className="project-status-circle" title={projectStatus}/>
              ) : (
              <div 
                className={`status-switch ${isActive ? 'active' : ''}`} 
                onClick={() => setOpenProjectStatusModal(true)} 
                title={projectStatus}
              >
                <div className="slider"></div>
              </div>
              )
            }
          </div>
          <button
            className="main-utils-btn"
            title="Assign Annotators"
            onClick={() => setOpenAssignmentsModal(true)}
          >
            <img src={AnnotatorsIcon} alt="AnnotatorsIcon" />
          </button>
          <button
            className="main-utils-btn"
            title="Import Annotations"
            onClick={() => setOpenImportAnnotationsModal(true)}
          >
            <img src={ImportAnnotationsIcon} alt="ImportAnnotationsIcon" />
          </button>
          <button
            className="main-utils-btn"
            title="Export Annotations"
            onClick={() => exportProjectAnnotations()}
          >
            <img src={DownloadIcon} alt="DownloadIcon" />
          </button>
        </div>
      </div>
  );
}

export default AdminProjectInstancesUtilsBar;
