import React, { useState, useEffect } from "react";
import filterIcon from "../../../images/filter-dark.png";
import addIcon from "../../../images/add.png";
import sortIcon from "../../../images/sort-dark.png";
import sortDownIcon from "../../../images/sort-down-dark.png";
import "../UtilsBar.css";
import api from '../../../axiosApi/api';

function AdminProjectsUtilsBar({ allProjects, filteredProjects, setFilteredProjects, openFiltersModal, openCreationModal }) {
  const [availableModalities, setAvailableModalities] = useState([]);
  const [availableTaskTypes, setAvailableTaskTypes] = useState([]);
  const [selectedModalities, setSelectedModalities] = useState([]);
  const [selectedTaskTypes, setSelectedTaskTypes] = useState([]);
  const [dropdownTaskTypes, setDropdownTaskTypes] = useState([]);

  const [selectedSortOption, setSelectedSortOption] = useState('Sort By');

  // New state for controlling dropdown visibility
  const [isModalityDropdownOpen, setModalityDropdownOpen] = useState(false);
  const [isTaskTypeDropdownOpen, setTaskTypeDropdownOpen] = useState(false);

  // Handle change of selected modalities
  useEffect(() => {    
    if (availableModalities && availableTaskTypes) {
      if (selectedModalities.length === 0){
        setFilteredProjects(allProjects);
        setDropdownTaskTypes(availableTaskTypes);
      }
      else{
        setDropdownTaskTypes(availableTaskTypes.filter(taskType => selectedModalities.includes(taskType.modality)));
        const filteredByModalityProjects = allProjects.filter(project => selectedModalities.includes(project.projectModality.name));
        setFilteredProjects(filteredByModalityProjects);
      }
    }
  }, [selectedModalities, availableModalities, availableTaskTypes]);

  // Handle change of selected task types
  useEffect(() => {    
    if (availableTaskTypes) {
      if (selectedTaskTypes.length === 0){
        let filteredByModalityProjects = []
        if (selectedModalities.length === 0)
          filteredByModalityProjects = allProjects;
        else
          filteredByModalityProjects = allProjects.filter(project => selectedModalities.includes(project.projectModality.name));
        setFilteredProjects(filteredByModalityProjects);
      }
      else{
        const filteredByTaskTypeProjects = allProjects.filter(project => selectedTaskTypes.includes(project.projectTaskType.name));
        setFilteredProjects(filteredByTaskTypeProjects);
      }
    }
  }, [selectedTaskTypes, availableTaskTypes]);

  // Fetch modalities and task types
  useEffect(() => {
    api.get('/api/modalities', {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
    }).then((res) => {
      setAvailableModalities(res.data.modalities);
    }).catch((error) => {
      console.log(error);
    });

    // Fetch available task types
    api.get('/api/task-types', {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
    }).then((res) => {
      const fetchedTaskTypes = res.data.task_types;
      setAvailableTaskTypes(fetchedTaskTypes);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  const handleModalityChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedModalities.includes(selectedValue)) {
      setSelectedModalities(selectedModalities.filter(modality => modality !== selectedValue));
    } else {
      setSelectedModalities([...selectedModalities, selectedValue]);
    }
  };

  const handleTaskTypeChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedTaskTypes.includes(selectedValue)) {
      setSelectedTaskTypes(selectedTaskTypes.filter(task => task !== selectedValue));
    } else {
      setSelectedTaskTypes([...selectedTaskTypes, selectedValue]);
    }
  };

  return (
    <div className="utils-container">
      <div className='filters-section'>
        {/* <div className="filters-header">
          <img src={filterIcon} alt="filter" />
          <div>Filters</div>
        </div> */}

        <div className="filters">
          {/* Modality Dropdown */}
          <div className="checkbox-dropdown">
            <button 
              className="dropdown-toggle" 
              onClick={() => setModalityDropdownOpen(!isModalityDropdownOpen)}
            >
              Modality:
            </button>
            <div className={`dropdown-menu ${isModalityDropdownOpen ? 'open' : ''}`}>
              <label>
                <input 
                  type="checkbox" 
                  checked={selectedModalities.length === 0} 
                  onChange={() => setSelectedModalities([])}
                />
                All modalities
              </label>
              {availableModalities.map((modality, index) => (
                <label key={index}>
                  <input 
                    type="checkbox" 
                    value={modality.name} 
                    checked={selectedModalities.includes(modality.name)} 
                    onChange={handleModalityChange}
                  />
                  {modality.name}
                </label>
              ))}
            </div>
          </div>

          {/* Task Type Dropdown */}
          <div className="checkbox-dropdown">
            <button 
              className="dropdown-toggle" 
              onClick={() => setTaskTypeDropdownOpen(!isTaskTypeDropdownOpen)}
            >
              Task Type:
            </button>
            <div className={`dropdown-menu ${isTaskTypeDropdownOpen ? 'open' : ''}`}>
              <label>
                <input 
                  type="checkbox" 
                  checked={selectedTaskTypes.length === 0} 
                  onChange={() => setSelectedTaskTypes([])}
                />
                All task types
              </label>
              {dropdownTaskTypes.map((task, index) => (
                <label key={index}>
                  <input 
                    type="checkbox" 
                    value={task.name} 
                    checked={selectedTaskTypes.includes(task.name)} 
                    onChange={handleTaskTypeChange}
                  />
                  {task.description}
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>

      <button className="create-project-btn" onClick={() => openCreationModal()}>
        <p className="create-btn-text">Create Project</p>
        <img src={addIcon} className="create-btn-icon" alt="addIcon" />
      </button>
    </div>
  );
}

export default AdminProjectsUtilsBar;
