import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import api from '../../axiosApi/api';

import logoImage from '../../images/logo.png';
import tickIcon from '../../images/tick.png';
import errorIcon from '../../images/error.png';
import infoIcon from '../../images/info.png';
import invisibilityIcon from '../../images/invisibility.png';
import visibilityIcon from '../../images/visibility.png';
import './AuthPages.css';

const SignInPage = () => {
    const navigate = useNavigate();
    const userRef = useRef(null);
    const passwordRef = useRef(null);
    const usernameInfoRef = useRef(null);
    const passwordInfoRef = useRef(null);
    const errRef = useRef(null);
    const infoPopupTimeoutRef = useRef(null);
    const errorPopupTimeoutRef = useRef(null);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [validUsername, setValidUsername] = useState(true);
    const [validPassword, setValidPassword] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [usernameInfoIsVisible, setUsernameInfoIsVisible] = useState(false);
    const [passwordInfoIsVisible, setPasswordInfoIsVisible] = useState(false);
    const [usernameInfoPosition, setUsernameInfoPosition] = useState({ top: 0, left: 0 });
    const [passwordInfoPosition, setPasswordInfoPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        if (usernameInfoRef.current && passwordInfoRef.current) {
            const usernameInfoRect = usernameInfoRef.current.getBoundingClientRect();
            const passwordInfoRect = passwordInfoRef.current.getBoundingClientRect();

            setUsernameInfoPosition({
                top: usernameInfoRect.top + window.scrollY,
                left: usernameInfoRect.left
            });

            setPasswordInfoPosition({
                top: passwordInfoRect.top + window.scrollY,
                left: passwordInfoRect.left
            });
        }
    }, [usernameInfoIsVisible, passwordInfoIsVisible]);

    const togglePasswordVisibility = () => {
        setPasswordVisible(prev => !prev);
    };

    const handleUsernameInfoMouseEnter = () => {
        setPasswordInfoIsVisible(false);
        clearTimeout(infoPopupTimeoutRef.current);
        setUsernameInfoIsVisible(true);
    };

    const handleUsernameInfoMouseLeave = () => {
        infoPopupTimeoutRef.current = setTimeout(() => {
            setUsernameInfoIsVisible(false);
        }, 500);
    };

    const handlePasswordInfoMouseEnter = () => {
        setUsernameInfoIsVisible(false);
        clearTimeout(infoPopupTimeoutRef.current);
        setPasswordInfoIsVisible(true);
    };

    const handlePasswordInfoMouseLeave = () => {
        infoPopupTimeoutRef.current = setTimeout(() => {
            setPasswordInfoIsVisible(false);
        }, 500);
    };

    const popupError = (errorMsg) => {
        setErrMsg(errorMsg);
        setErrorVisible(true);
        clearTimeout(errorPopupTimeoutRef.current);
        errorPopupTimeoutRef.current = setTimeout(() => {
            setErrorVisible(false);
        }, 3000);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (username === '') {
            setValidUsername(false);
            popupError("Username must not be empty.");
            userRef.current.focus();
            return;
        } else {
            setValidUsername(true);
        }

        if (password === '') {
            setValidPassword(false);
            popupError('Password must not be empty.');
            passwordRef.current.focus();
            return;
        } else {
            setValidPassword(true);
        }

        try {
            const response = await api.post('/api/auth/login', { username, password });
            const access_token = response.data.access_token;
            localStorage.setItem('username', username);
            localStorage.setItem('jwtToken', access_token);

            const userRole = response.data.user_info.user_role;
            localStorage.setItem('userRole', userRole);
            if (userRole === "Annotator") {
                navigate('/annotator/dashboard');
            } else if (userRole === "Admin") {
                navigate("/admin/dashboard");
            }
        } catch (error) {
            if (!error?.response) {
                popupError('No server response.');
            } else if (error.response?.status === 500) {
                popupError('An unexpected error occurred.');
            } else if (error.response?.status === 401) {
                popupError('Invalid username or password.');
                setValidUsername(false);
                setValidPassword(false);
            } else {
                setErrMsg('Registration Failed');
            }
        }
    };

    return (
        <div className='App'>
            <div className="auth-page">
                <form className="form-container" onSubmit={handleSubmit}>
                    <div className="form-content">
                        <div className="logo-container">
                            <img src={logoImage} className="logo" alt="Logo" />
                        </div>
                        <h1 className="form-header">Sign In</h1>

                        <div className="form-group">
                            <label htmlFor="username" className="form-label">
                                <div className="form-label-title-container">
                                    <span className="form-label-title">Username</span>
                                    <img
                                        className={`validity-icon ${validUsername ? '' : 'visible'}`}
                                        src={errorIcon}
                                        alt="errorIcon"
                                    />
                                </div>
                                <span
                                    className="info-icon"
                                    onMouseEnter={handleUsernameInfoMouseEnter}
                                    onMouseLeave={handleUsernameInfoMouseLeave}
                                    ref={usernameInfoRef}
                                >
                                    <img className="info-icon" src={infoIcon} alt="InfoIcon" />
                                </span>
                                <div
                                    className={`info-popup ${usernameInfoIsVisible ? 'visible' : ''}`}
                                    style={{ top: usernameInfoPosition.top, left: usernameInfoPosition.left }}
                                >
                                    <div className="info-popup-text">
                                        Username must:
                                        <ul>
                                            <li>Begin with a letter</li>
                                            <li>Contain 4 to 24 characters</li>
                                            <li>Contain Letters, Numbers, Underscores, or Hyphens</li>
                                        </ul>
                                    </div>
                                </div>
                            </label>
                            <input
                                type="text"
                                id="username"
                                className={`form-input ${validUsername ? "" : 'invalid'}`}
                                placeholder="Enter your username"
                                ref={userRef}
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                                aria-describedby="uidnote"
                                autoComplete="off"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password" className="form-label">
                                <div className="form-label-title-container">
                                    <span className="form-label-title">Password</span>
                                    <img
                                        src={`${validPassword ? tickIcon : errorIcon}`}
                                        className={`validity-icon ${validPassword ? '' : 'visible'}`}
                                        alt="ValidityIcon"
                                    />
                                </div>
                                <span
                                    className="info-icon"
                                    onMouseEnter={handlePasswordInfoMouseEnter}
                                    onMouseLeave={handlePasswordInfoMouseLeave}
                                    ref={passwordInfoRef}
                                >
                                    <img className="info-icon" src={infoIcon} alt="InfoIcon" />
                                </span>
                                <div
                                    className={`info-popup ${passwordInfoIsVisible ? 'visible' : ''}`}
                                    style={{ top: passwordInfoPosition.top, left: passwordInfoPosition.left }}
                                >
                                    <div className="info-popup-text">
                                        Password must:
                                        <ul>
                                            <li>Contain 8 to 24 characters</li>
                                            <li>Include Uppercase and Lowercase letters, a number and a special character</li>
                                            <li>Allowed special characters: - ! @ # $ %</li>
                                        </ul>
                                    </div>
                                </div>
                            </label>
                            <div className="visibility-input-container">
                                <input
                                    type={`${passwordVisible ? 'text' : 'password'}`}
                                    id="password"
                                    className={`form-input ${validPassword ? "" : 'invalid'}`}
                                    placeholder="Enter your password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    ref={passwordRef}
                                    value={password}
                                    aria-describedby="passwordnote"
                                    autoComplete="off"
                                />
                                <div className="password-toggle" onClick={togglePasswordVisibility}>
                                    <img className="visibility-icon" src={`${passwordVisible ? visibilityIcon : invisibilityIcon}`} alt="VisibilityIcon" />
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="submit-btn">Sign In</button>

                        <p className="navigation-text">Not registered yet? <Link to="/" className="navigation-link">Sign Up</Link></p>

                        <p ref={errRef} className={`error-popup ${errorVisible ? 'visible' : ''}`}>{errMsg}</p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignInPage;
