import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import DashboardSideBar from "../../components/DashboardSideBar/DashboardSideBar";
import AdminProjectsDashboard from "../../components/Dashboards/ProjectsDashboard/AdminProjectsDashboard";
import DatasetsDashboard from "../../components/Dashboards/DatasetsDashboard/DatasetsDashboard";
import AnnotatorsDashboard from "../../components/Dashboards/AnnotatorsDashboard/AnnotatorsDashboard";
import AdminProjectInstancesDashboard from "../../components/Dashboards/ProjectInstancesDashboard/AdminProjectInstancesDashboard";
import InstanceDashboard from "../../components/Dashboards/InstanceDashboard/InstanceDashboard";
import InstanceAutomaticAnnotationsDashboard from "../../components/Dashboards/InstanceAutomaticAnnotationsDashboard/InstanceAutomaticAnnotationsDashboard";
import ModelsDashboard from "../../components/Dashboards/ModelsDashboard/ModelsDashboard";
import RequireAdmin from "../../components/RequireAdminComponent/RequireAdmin";
import './DashboardPage.css';


function AdminDashboardPage() {
  const [selectedDashboard, setSelectedDashboard] = useState("projects");
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/admin/dashboard") {
      setSelectedDashboard("projects")
      navigate("/admin/dashboard/projects");
    }
  }, [navigate]); 

  return (
    <div className="dashboard-page">
      <DashboardSideBar
        selectedDashboard={selectedDashboard}
        onDashboardChange={setSelectedDashboard}
      />
      <div className="dashboard-section">
        <Routes>
          <Route exact path="/admin/dashboard/*" element={<RequireAdmin><AdminDashboardPage /></RequireAdmin>} />
          <Route exact path="projects" element={<RequireAdmin><AdminProjectsDashboard/></RequireAdmin>} />
          <Route exact path="projects/:projectId" element={<RequireAdmin><AdminProjectInstancesDashboardWrapper/></RequireAdmin>} />
          <Route exact path="projects/:projectId/data-chunks/:dataChunkId/instances/:instanceId" element={<RequireAdmin><InstanceDashboard/></RequireAdmin>} />
          <Route exact path="projects/:projectId/data-chunks/:dataChunkId/instances/:instanceId/automatic-annotations" element={<RequireAdmin><InstanceAutomaticAnnotationsDashboard/></RequireAdmin>} />
          <Route exact path="datasets" element={<RequireAdmin><DatasetsDashboard/></RequireAdmin>} />
          <Route exact path="annotators" element={<RequireAdmin><AnnotatorsDashboard/></RequireAdmin>} />
          <Route exact path="models" element={<RequireAdmin><ModelsDashboard/></RequireAdmin>} />
        </Routes>
      </div>
    </div>
  );
}

// Memoized wrapper component for AdminProjectInstancesDashboard
const AdminProjectInstancesDashboardWrapper = () => {
  const { projectId } = useParams();

  return <AdminProjectInstancesDashboard projectId={projectId} />;
};

export default AdminDashboardPage;