import React, { useState, useEffect } from "react";
import xIcon from "../../../images/x-icon.png";
import uploadIcon from "../../../images/upload-compressed-icon.png";

import "../Modals.css";
import "./UploadDatasetModal.css";
import BackgroundOverlay from '../../BackgroundOverlay/BackgroundOverlay';

const UploadDatasetModal = ({ open, onClose, uploadDataset }) => {
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [controller, setController] = useState(new AbortController());

  const fileFormatIcons = {
    "wav": "wav.png",
    "mp3": "mp3.png",
    "png": "png.png",
    "jpg": "jpg.png",
    "Dockerfile": "dockerfile.png",
    "txt": "txt.png",
    "py": "python.png",
    "zip": "zip.png",
    "pdf": "pdf.png",
    "json": "json.png",
    "psd": "psd.png",
    "misc": "misc.png"
  }


  useEffect(() => {
    return () => controller.abort();
  }, [controller]);

  if (!open) return null;


  const configureFileIcon = async (format) => {
    let iconName;
    if (format in fileFormatIcons) {
        iconName = fileFormatIcons[format];
    } else {
        iconName = fileFormatIcons['misc'];
    }

    try {
        const icon = await import(`../../../images/file-formats/${iconName}`);
        return icon; 
    } catch (error) {
        console.error("Error importing icon:", error);
    }
  };


  const handleFolderSelection = async (event) => {
    setIsLoading(true);

    try {
      const newController = new AbortController();
      setController(newController);
  
      const files = event.target.files;
      if (!files.length) {
        console.error('No files selected');
        setIsLoading(false);
        return;
      }
      
      // Extract the folder name from the first file's webkitRelativePath
      const firstFile = files[0];
      const firstFilePath = firstFile.webkitRelativePath;
      const selectedFolderName = firstFilePath.split('/')[0];
      setFolderName(selectedFolderName)
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const relativePath = file.webkitRelativePath || file.name;
  
        // Extract file details similar to previous logic
        const slashSplitTokens = relativePath.split('/');
        const parentPath = slashSplitTokens.length === 2 ? 'root' : slashSplitTokens.slice(0, -1).join("/");
        
        let fileName;
        const dotSplitTokens = file.name.split('.');
        if (file.name.includes("."))
            fileName = dotSplitTokens.length === 2 ? dotSplitTokens[0] : dotSplitTokens.slice(0, -1).join(".");
        else
            fileName = file.name;

        let fileType;
        if (fileName === "Dockerfile")
            fileType = "Dockerfile";
        else
            fileType = dotSplitTokens.length === 2 ? dotSplitTokens[1] : dotSplitTokens[dotSplitTokens.length - 1];

        const fileSize = file.size;

        const icon = await configureFileIcon(fileType);

        const instance = {
          "relativePath": relativePath,
          "fileName": fileName,
          "fileType": fileType,
          "fileSize": fileSize,
          "parentPath": parentPath,
          "icon": icon.default
        };
  
        setSelectedInstances(prevInstances => [...prevInstances, instance]);
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error('Error selecting folder:', error);
      setIsLoading(false);
    }
  };

  const handleUpload = async () => {
    try {
      await uploadDataset(folderName, selectedInstances);
      clearState();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const clearState = () => {
    setSelectedInstances([]);
    setFolderName("");
  };

  const onCloseClick = () => {
    clearState();
    onClose();
  };

  return (
    <>
      <BackgroundOverlay />
      <div className="dataset-upload-modal">
        <div className="datasets-modal-header">
          <h3>Add a Dataset</h3>
          <img src={xIcon} className="x-icon" onClick={onCloseClick} alt="xIcon" />
        </div>
        <div className="datasets-modal-body">
          {isLoading ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : selectedInstances.length === 0 ? (
            <div className="instances-selection-section">
              <div className="instances-selection-body">
                <label htmlFor="fileInput" className="file-input-label">
                    <img src={uploadIcon} alt="Upload Icon" />
                    <span>Select a folder of files to upload</span>
                </label>
                <input 
                    type="file" 
                    id="fileInput" 
                    style={{ display: 'none' }} 
                    webkitdirectory="" 
                    mozdirectory="" 
                    onChange={handleFolderSelection} 
                />
              </div>
            </div>
          ) : (
            <div className="upload-finalization-section">
              <div className='selected-instances-container'>
                <div className="selected-instances-header">
                  <span> <h6>Files to Upload</h6> ({selectedInstances.length} files) </span>
                  <button className="remove-instances-btn" onClick={() => setSelectedInstances([])}>
                    Clear
                  </button>
                </div>
                <div className="selected-instances-list">
                  <div className="instances-list-header">
                    <div className="header-field"></div>
                    <div className="header-field">Name</div>
                    <div className="header-field">Format</div>
                    <div className="header-field">Size</div>
                    <div className="header-field">Parent Dir</div>
                  </div>
                  {selectedInstances.map(instance => (
                    <div key={instance.relativePath} className="instances-list-item">
                      <div className="instance-card-field" title={instance.fileType}>
                          <img src={instance.icon}/>
                      </div>
                      <div className="instance-card-field">
                        <div className="field-text" title={instance.fileName}>{instance.fileName}</div>
                      </div>
                      <div className="instance-card-field">
                        <div className="field-text" title={instance.fileType}>{instance.fileType}</div>
                      </div>
                      <div className="instance-card-field">
                        <div className="field-text" title={instance.fileSize}>{instance.fileSize}</div>
                      </div>
                      <div className="instance-card-field">
                        <div className="field-text" title={instance.parentPath}>{instance.parentPath}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='folder-name-container'>
                <h6>Folder name</h6>
                <input
                  type="text"
                  id="folder-name-input"
                  placeholder={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  value={folderName}
                  aria-describedby="uidnote"
                  autoComplete="False"
                />
              </div>
            </div>
          )}
        </div>
        <div className="datasets-modal-footer">
          <button
            className="submission-footer-btn"
            disabled={selectedInstances.length === 0 || isLoading || folderName===""}
            onClick={() => handleUpload()}
          >Upload</button>
        </div>
      </div>
    </>
  );
};

export default UploadDatasetModal;
