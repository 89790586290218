import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import api from '../../../axiosApi/api';

import FiltersModal from '../../Modals/FiltersModal/FiltersModal';
import AnnotatorProjectsUtilsBar from '../../UtilsBar/ProjectsUtilsBar/AnnotatorProjectsUtilsBar';

import "./../Dashboard.css";
import "./ProjectsDashboard.css";

import naIcon from "../../../images/naNew.png";
import ErrorIcon from "../../../images/error-icon.png"


function AnnotatorProjectsDashboard() {
  const location = useLocation();
  const currentPath = location.pathname;

  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);


  const fetchProjects = async () => {
    try {
      const res = await api.get('/api/projects', {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
      });
      setError(false);
      setData(res.data.projects);
      setFilteredData(res.data.projects);
      setProjectsLoading(false);
    } catch (error) {
      setError(true);
      console.log(error);
      setProjectsLoading(false);
    }
  };


  const applyFilters = (setFromDate, setToDate, setModalities, setTypes) => {
    const filteredProjects = data.filter((project) => {
      const creationDate = project['creation_date'];
      const [day, month, year] = creationDate.split('-');
      const formatedCreationDate = new Date(`20${year}`, month - 1, day);

      const allModalitiesAreUnchecked = Object.values(setModalities).every(value => !value);
      const allTypesAreUnchecked = Object.values(setTypes).every(value => !value);

      return (
        (setFromDate === null || formatedCreationDate > setFromDate) &&
        (setToDate === null || formatedCreationDate < setToDate) &&
        (allModalitiesAreUnchecked || setModalities[project.modality]) &&
        (allTypesAreUnchecked || setTypes[project.type])
      );
    });

    setFilteredData(filteredProjects);
  };


  function configureStatusColor(status) {
    let backgroundColor;

    switch (status) {
        case 'Complete':
            backgroundColor = 'lightblue';
            break;
        case 'Paused':
            backgroundColor = 'purple';
            break;
        case 'Active':
            backgroundColor = 'lightgreen';
            break;
        default:
            backgroundColor = 'gray'; // Default color if status is unknown
    }

    return {
      backgroundColor: backgroundColor,
    };
  }


  return (
    <>
      <FiltersModal open={openFiltersModal} onClose={() => setOpenFiltersModal(false)} applyFilters={applyFilters} />
      <section className="dashboard projects-dashboard">
        <h2>Dashboards / <b>Projects Dashboard</b></h2>
        <AnnotatorProjectsUtilsBar
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          openFiltersModal={() => setOpenFiltersModal(true)}
        />
        <div className="project-cards-container">
          {error ? (
            <div className="message-container">
              <img src={ErrorIcon} className="message-icon" alt="Error icon" />
              <p className="message-text-main">An unexpected error occurred.</p> 
              <p className="message-text">Please try again later.</p>
            </div>
          ) : projectsLoading ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : (
            filteredData.length > 0 ? (
              <div className="project-cards-grid">
                {filteredData.map((project) => (
                  <div key={project.projectId} className="project-card">
                    <div className="project-details">
                      <div className="project-title-and-status">
                        <div className="project-card-title" title={project.projectName}>{project.projectName}</div>
                        <div className="project-status" style={configureStatusColor(project.projectStatus)} title={project.projectStatus}/>
                      </div>
                      <div className="project-card-modality-and-type">
                        <span>{project.projectModality["name"]}</span> - <span>{project.projectTaskType["name"]}</span>
                      </div>
                      <span className="project-card-date">{project.creationDate}</span>
                      <span className="project-card-description">{project.projectDescription}</span>
                    </div>
                    <button className="project-card-btn">
                      <Link
                        className="btn-link"
                        to={`${currentPath}/${project.projectId}`}
                        state={{
                          projectType: project.projectTaskType,
                          projectModality: project.projectModality,
                          projectStatus: project.projectStatus,
                          labels: project.projectLabels,
                          assistanceModel: project.assistanceModel
                        }}
                      >
                        Open <span>&gt;</span>
                      </Link>
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <div className="message-container">
                <img src={naIcon} className="message-icon empty-box-icon" alt="naIcon" />
                <span className="message-text">No assigned projects</span>
              </div>
            )
          )}
        </div>
      </section>
    </>
  );
}

export default AnnotatorProjectsDashboard;
