import ArchitectureImg from "../../../images/ArchitectureImg.png";


function Architecture() {
    return (
        <>
            <h3>Architecture</h3>
            <h5>Overview</h5>
            <p>The architecture of the developed end-to-end pipeline can be deconstructed into three web application layers:</p>
            <ul>
                <li><b>Front-End:</b> The front-end layer of the web application is the user interface that users interact with directly. It typically consists of web pages, user interfaces, and components that are rendered in the browser.</li>
                <li><b>Back-End:</b> The back-end layer of the web application is responsible for managing data, processing requests from the front-end, and interacting with the database. It includes the server, application logic, and APIs (Application Programming Interfaces) that handle business logic, authentication, authorization, and data processing.</li>
                <li><b>Database:</b> The database layer of the web application stores and manages the application's data. </li>
            </ul>
            <p>The selection of appropriate components for each layer was a process that greatly depended on the platform's specific requirements and scalability needs. Consequently, following a comprehensive evaluation of all the candidate frameworks and libraries, the architecture of the annotation pipeline was decided to be designed as illustrated in the following figure</p>
            
            <div className="img-container">
                <img src={ArchitectureImg} alt="Architecture"/>
            </div>

            <h5>Core Components</h5>
            <p>As depicted above, the annotation pipeline consists of numerous components that interact with each other, with the aim of ensuring the seamless operation of the platform. The set of components that comprise the backbone of the pipeline is summarized in the following table.</p>

            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Technology</th>
                        <th>Description</th>
                        <th>Version</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><a href="https://react.dev/" target="_blank" rel="noopener noreferrer"> ReactJS </a></td>
                        <td>Renders interactive elements and displays data in the web browser</td>
                        <td>18.2.0</td>
                    </tr>
                    <tr>
                        <td><a href="https://labelstud.io/guide/frontend_reference" target="_blank" rel="noopener noreferrer"> Label Studio Front-End </a></td>
                        <td>Provides a user-friendly interface for annotating data using Label Studio's features and tools</td>
                        <td>1.0.1</td>
                    </tr>
                    <tr>
                        <td><a href="https://flask.palletsprojects.com/en/3.0.x/" target="_blank" rel="noopener noreferrer"> Flask </a></td>
                        <td>Manages server-side logic, handles HTTP requests, and interacts with the database to provide data to the frontend</td>
                        <td>2.2.3</td>
                    </tr>
                    <tr>
                        <td><a href="https://www.mongodb.com/" target="_blank" rel="noopener noreferrer"> MongoDB </a></td>
                        <td>Stores and manages structured data in a document-oriented format</td>
                        <td>4.4.2</td>
                    </tr>
                    <tr>
                        <td><a href="https://axios-http.com/docs/intro" target="_blank" rel="noopener noreferrer"> Axios </a></td>
                        <td>Facilitates communication between the frontend (React) and backend (Flask) by sending HTTP requests to API endpoints</td>
                        <td>1.3.4</td>
                    </tr>
                    <tr>
                        <td><a href="https://flask-restful.readthedocs.io/en/latest/" target="_blank" rel="noopener noreferrer"> Flask-RESTful </a></td>
                        <td>Simplifies the creation of RESTful APIs in Flask</td>
                        <td>0.3.9</td>
                    </tr>
                    <tr>
                        <td><a href="https://flask-jwt-extended.readthedocs.io/en/stable/" target="_blank" rel="noopener noreferrer"> Flask JWT-Extended </a></td>
                        <td>Manages user authentication and authorization in Flask applications using JSON Web Tokens (JWTs)</td>
                        <td>4.4.4</td>
                    </tr>
                    <tr>
                        <td><a href="http://mongoengine.org/" target="_blank" rel="noopener noreferrer"> MongoEngine </a></td>
                        <td>Simplifies interaction with MongoDB databases in Flask applications by providing an object-oriented interface</td>
                        <td>0.27.0</td>
                    </tr>
                </tbody>
            </table>

        </>
    )
}

export default Architecture;