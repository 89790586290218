import React, { useState } from 'react';
import './AboutPage.css';
import gitIcon from "../../images/git.png";
import logoIcon from "../../images/logo-small.png";

import AboutUs from './OptionPages/AboutUs';
import Motivation from './OptionPages/Motivation';
import Features from './OptionPages/Features';
import Architecture from './OptionPages/Architecture'
import Database from './OptionPages/Database'

import BoundingBoxes from './OptionPages/BoundingBoxes'
import Polygons from './OptionPages/Polygons'
import ImageClassification from './OptionPages/ImageClassification'
import ImageCaptioning from './OptionPages/ImageCaptioning'
import AudioClassification from './OptionPages/AudioClassification'
import AudioCaptioning from './OptionPages/AudioCaptioning'
import SegmentsAudioClassification from './OptionPages/SegmentsAudioClassification'
import SegmentsAudioCaptioning from './OptionPages/SegmentsAudioCaptioning'
import AnnotatorsManagement from './OptionPages/AnnotatorsManagement'
import AutomaticAnnotations from './OptionPages/AutomaticAnnotations'
import Statistics from './OptionPages/Statistics'
import AnnotationsExportation from './OptionPages/AnnotationsExportation'
import ProjectCreation from './OptionPages/ProjectCreation'


function AdminAboutPage() {
    const [selectedOption, setSelectedOption] = useState('Motivation');
    const userRole = localStorage.getItem('userRole');


    const renderContent = () => {
        switch (selectedOption) {
            case 'Motivation':
                return (<Motivation />);
            case 'Features':
                return (<Features />);
            case 'Architecture':
                return (<Architecture/>);
            case 'Database':
                return (<Database/>);
            case 'BoundingBoxes':
                return (<BoundingBoxes /> );
            case 'Polygons':
                return (<Polygons /> );
            case 'ImageClassification':
                return (<ImageClassification /> );
            case 'ImageCaptioning':
                return (<ImageCaptioning /> );
            case 'AudioClassification':
                return (<AudioClassification /> );
            case 'SegmentsAudioClassification':
                return (<SegmentsAudioClassification /> );
            case 'AudioCaptioning':
                return (<AudioCaptioning /> );
            case 'SegmentsAudioCaptioning':
                return (<SegmentsAudioCaptioning /> );
            case 'ProjectCreation':
                return (<ProjectCreation /> );
            case 'AnnotatorsManagement':
                return (<AnnotatorsManagement /> );
            case 'AutomaticAnnotations':
                return (<AutomaticAnnotations /> );
            case 'Statistics':
                return (<Statistics /> );
            case 'AnnotationsExportation':
                return (<AnnotationsExportation /> );
            case 'AboutUs':
                return <AboutUs />;
            // Add more cases for other options
            default:
                return (
                    <div>
                        <h3>Default Content</h3>
                        <p>Select an option from the sidebar to view content.</p>
                    </div>
                );
        }
    };


    return (
        <div className="about-container">

            <div className='side-options'>
                <div className='options'>
                    <div className='section'>
                        <div className='option-header'>OVERVIEW</div>
                        <div className='option' onClick={() => setSelectedOption('Motivation')}>Motivation</div>
                        <div className='option' onClick={() => setSelectedOption('Features')}>Features</div>
                    </div>
                    <div className='section'>
                        <div className='option-header'>ARCHITECTURE</div>
                        <div className='option' onClick={() => setSelectedOption('Architecture')}>Architecture</div>
                        <div className='option' onClick={() => setSelectedOption('Database')}>Database Design</div>
                    </div>
                    <div className='section'>
                        <div className='option-header'>IMAGE ANNOTATION TASKS</div>
                        <div className='option' onClick={() => setSelectedOption('BoundingBoxes')}>Object Detection with Bounding Boxes</div>
                        <div className='option' onClick={() => setSelectedOption('Polygons')}>Object Detection with Polygons</div>
                        <div className='option' onClick={() => setSelectedOption('ImageClassification')}>Image Classification</div>
                        <div className='option' onClick={() => setSelectedOption('ImageCaptioning')}>Image Captioning</div>
                    </div>
                    <div className='section'>
                        <div className='option-header'>AUDIO ANNOTATION TASKS</div>
                        <div className='option' onClick={() => setSelectedOption('AudioClassification')}>Audio Classification</div>
                        <div className='option' onClick={() => setSelectedOption('SegmentsAudioClassification')}>Audio Classification with Segments</div>
                        <div className='option' onClick={() => setSelectedOption('AudioCaptioning')}>Audio Captioning</div>
                        <div className='option' onClick={() => setSelectedOption('SegmentsAudioCaptioning')}>Audio Captioning with Segments</div>
                    </div>
                    {userRole === "Admin" && (
                        <div className='section'>
                        <div className='option-header'>UTILITIES</div>
                        <div className='option' onClick={() => setSelectedOption('ProjectCreation')}>Project Creation</div>
                        <div className='option' onClick={() => setSelectedOption('AnnotatorsManagement')}>Annotators Management</div>
                        <div className='option' onClick={() => setSelectedOption('AutomaticAnnotations')}>(Semi-) Automatic Annotations</div>
                        <div className='option' onClick={() => setSelectedOption('Statistics')}>Statistics</div>
                        <div className='option' onClick={() => setSelectedOption('AnnotationsExportation')}>Annotations exportation</div>
                    </div>
                    )}
                    <div className='section'>
                        <div className='option-header' onClick={() => setSelectedOption('AboutUs')}>ABOUT US</div>
                    </div>
                </div>
                <div className='git-footer'>
                    <div className="git-logo-section">
                        <img src={gitIcon} alt="GitIcon"/> Find us on GitHub
                    </div>
                    <a href="https://github.com/magcil" target="_blank" rel="noopener noreferrer"> MagCIL </a>
                </div>
            </div>
            <div className='content'>
                {renderContent()}
            </div>
        </div>
    )
}


export default AdminAboutPage;